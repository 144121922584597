import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-about-style-one',
    templateUrl: './about-style-one.component.html',
    styleUrls: ['./about-style-one.component.scss']
})
export class AboutStyleOneComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}