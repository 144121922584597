<div class="motivation-subscribe-area bg-f9f9f9 ptb-100">
    <div class="container">
        <div class="subscribe-content">
            <span class="sub-title">Newsletter</span>
            <h2 class="jost-font font-weight-bold">Subscribe To My Newsletter</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            <form class="newsletter-form">
                <input type="text" class="input-newsletter" placeholder="Enter your email address" name="EMAIL" required autocomplete="off">
                <button type="submit" class="default-btn"><i class="flaticon-user"></i>Subscribe Now<span></span></button>
            </form>
        </div>
    </div>
    <div class="motivation-shape-7">
        <img src="assets/img/motivation-home/motivation-shape-7.png" alt="image">
    </div>
    <div class="motivation-shape-8">
        <img src="assets/img/motivation-home/motivation-shape-8.png" alt="image">
    </div>
    <div class="motivation-shape-9">
        <img src="assets/img/motivation-home/motivation-shape-9.png" alt="image">
    </div>
    <div class="motivation-shape-10">
        <img src="assets/img/motivation-home/motivation-shape-10.png" alt="image">
    </div>
</div>