<div class="kitchen-subscribe-area">
    <div class="container">
        <div class="kitchen-inner-subscribe ptb-70">
            <div class="subscribe-content">
                <span class="sub-title">Newsletter</span>
                <h2 class="font-weight-black">Subscribe To My Newsletter</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <form class="newsletter-form">
                    <input type="text" class="input-newsletter" placeholder="Enter your email address" name="EMAIL" required autocomplete="off">
                    <button type="submit" class="default-btn"><i class="flaticon-user"></i>Subscribe Now<span></span></button>
                </form>
            </div>
        </div>
    </div>
</div>