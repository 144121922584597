<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Terms & Conditions</li>
            </ul>
            <h2>Terms & Conditions</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-12 col-md-12">
                <div class="about-content">
                        <div>
                            <h5>
                              <b>
                                Refund and Cancellation policies
                              </b>  
                            </h5>
                            <p>
                                Please read the subscription terms and conditions carefully before subscribing to any of the subscription plans, as once you have subscribed, you cannot change or cancel your subscription plan. Once you subscribe and make the required payments for any online subscriptions, it shall be final and there cannot be any changes or modifications to the same and neither will there be any refund.
                            </p>
                        </div>
                        <br/>
                        <div>
                            <h5>
                              <b>
                                Privacy Policy
                              </b>  
                            </h5>
                            <p>
                                This Privacy Policy (“Privacy Policy/Policy”) discloses the privacy practices for Edufinpact Labs Private Limited with regard to collection and use of the Personal Information (defined hereunder) of the customers, vendors, employees and the users and use of the online platform www.infinacademy.in. This Privacy Policy along with Terms of Use describes our practices regarding, including your choices in relation to how we collect, store, use, share and secure your Personal Information across our Website. It also describes your choices regarding use, access and correction of your Personal Information and your rights in relation to your Personal Information and how to contact us or supervisory authorities in the event you have a complaint.

                                By providing your consent to this Privacy Policy and accepting the Terms of Use, you agree to the collection, use and transfer of your Personal Information as set out in this Privacy Policy. If you do not agree with the terms of this Privacy Policy, please do not use/access this Website. You cannot use our logo trade mark and words copyright content. If found using the legal names or content this might attract legal actions.
                                
                                We will review this Privacy Policy from time to time to make sure it is up to date. If you are just a visitor, then please note that this Privacy Policy is subject to change at any time without notice. To make sure you are aware of any changes, please review this Policy periodically.
                                
                                By Personal Information, we mean any information that can either itself identify you as an individual ("Personally Identifying Information") or that can be connected to you indirectly by linking it to Personally Identifying Information. Please note that usage of the term Personal Information in this Privacy Policy includes sensitive personal data or information, wherever appropriate and/or mandated under applicable laws.
                            </p>
                        </div>

                        <br/>
                        <div>
                            <h5>
                              <b>
                                COLLECTION OF INFORMATION
                              </b>  
                            </h5>
                            
                                When you visit our Website, as part of a purchase of one of our courses or registering on our Website, we may collect a variety of Personal Information that you will voluntarily provide to us via one of our contact forms, via a chat or phone session, such as:

                                Contact Information, such as name, email address, display picture, mailing address, phone number, etc.
                                Billing Information, such as debit card number, credit card number and billing address.
                                Unique Identifiers, such as username or password.
                                Reviews or ratings, syllabus and course preferences
                                Information provided to us through our service, interaction with our customer service, participation in surveys or marketing promotions.
                                If you are an employee of Edufinpact labs Pvt. Ltd., we collect the following Personal Information:
                                
                                <ul >
                                    <li><span> Name </span></li>
                                    <li><span> Email address</span></li>
                                    <li><span> Contact number</span></li>
                                    <li><span> Date of birth</span></li>
                                    <li><span> Gender</span></li>
                                </ul>
                           


                          
                        </div>

                        <br/>
                        <div>
                            <h5>
                              <b>
                                SECURITY
                              </b>  
                            </h5>
                            <p>
                                We shall try to take all precautions to protect the Personal Information both online and offline. We will try to protect your information using technical and administrative security measures to reduce the risks of loss, misuse, unauthorized access, disclosure, and alteration. Some of the safeguards we use are firewalls and data encryption, physical access controls to our data centers and information access authorization controls. Only user passwords are encrypted in 64-bit encryption and stored because generally users use the same password on multiple sites, to prevent any kind of theft, piracy, or unauthorized access. If you believe your account has been abused or hacked, please contact us by sending us an email at infin@infinacademy.in.

                                We do not sell, transfer, or rent your Personal Information to third parties for their marketing purposes without your explicit consent and we only use your information as described in the Privacy Policy. We view protection of your privacy as a very important community principle. We understand clearly that you and your Personal Information is one of our most important assets.
                                
                                Under no circumstances, we rent, trade, transfer or share your Personal Information that we have collected with any other company for their marketing purposes without your consent. We reserve the right to communicate your Personal Information to any third party that makes a legally compliant request for its disclosure.
                            </p>
                        </div>

                        <br/>
                        <div>
                            <h5>
                              <b>
                                KEEPING YOUR PERSONAL INFORMATION SECURE
                              </b>  
                            </h5>
                            <p>
                                We have appropriate security measures to prevent your Personal Information from being accidentally lost or used or accessed unlawfully. Processing your Personal Information will be done only in an authorized manner and subject to a duty of confidentiality. We also have procedures in place to deal with any suspected data security breach. We will notify you about any applicable regulator of a suspected data security breach where we are legally required to do so.

                                Notwithstanding anything contained anywhere in this Policy; we cannot assure absolute security to your Personal Information and by entering or by logging into our Website, you explicitly agree not to sue us for any data breach.
                            </p>
                        </div>

                        <br/>
                        <div>
                            <h5>
                              <b>
                                COOKIES
                              </b>  
                            </h5>
                            <p>
                                We transfer cookies, which are small files containing a string of character, to your IP address, giving the browser distinct identification, to keep track of the user’s preferences. Furthermore, these files also help in logging-in faster and they act as a mechanism to determine user trends. The data thus retrieved from the user’s IP address, enables us to enhance our offers, including but not limited to more content in areas of greater interest to most users. Our Website uses "Cookies" to identify the areas of our Website that you have visited. A Cookie is a small piece of data stored on your computer or mobile device by your web browser. We use Cookies to personalize the Content that you see on our Website. Most web browsers can be set to disable the use of Cookies. However, if you disable Cookies, you may not be able to access functionality on our Website correctly or at all. We never place Personally Information in Cookies.
                            </p>
                        </div>

                        <br/>
                        <div>
                            <h5>
                              <b>
                                CONSULTING
                              </b>  
                            </h5>
                            <p>
                                We use third parties to help us provide services to You including the fulfilment of service, processing of payments, monitoring site activity, conducting surveys, maintaining our database, administering emails, and administering contents, and to provide aggregate, comparative information on the performance of our Website to us and a select group.
                            </p>
                        </div>

                        <br/>
                        <div>
                            <h5>
                              <b>
                                CHOICE
                              </b>  
                            </h5>
                            <p>
                                It is open for you to customize our usage of your personal information to communicate with you, to send you marketing information, how we provide you with customized and relevant advertising, and whether you want to stay signed into your account.

                                If you do not wish to receive marketing communications from us, you can unsubscribe from the link in the email you would receive or change your communication preferences or indicate your communication preferences. You can also reach us via chat, WhatsApp, call or email to block promotional communication to you. Keep in mind, we do not sell or rent your personal information to third parties for their marketing purposes without your explicit consent.
                            </p>
                        </div>
                </div>
            </div>
        </div>
    </div>
  
</div>


