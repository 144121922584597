<div class="kitchen-coach-banner-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="kitchen-coach-banner-image">
                    <img src="assets/img/kitchen-home/banner-young-woman.jpg" alt="business">
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="kitchen-coach-banner-content">
                    <h1 class="font-weight-black">Cooking is Really a Demonstration of Adoration</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua quis ipsum suspendisse.</p>
                    <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i>Join For Free<span></span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="kitchen-coach-large-shape">
        <img src="assets/img/kitchen-home/kitchen-large-shape.png" alt="image">
    </div>
    <div class="kitchen-shape-1">
        <img src="assets/img/kitchen-home/kitchen-shape-1.png" alt="image">
    </div>
    <div class="kitchen-shape-2">
        <img src="assets/img/kitchen-home/kitchen-shape-2.png" alt="image">
    </div>
    <div class="kitchen-shape-3">
        <img src="assets/img/kitchen-home/kitchen-shape-3.png" alt="image">
    </div>
    <div class="kitchen-shape-4">
        <img src="assets/img/kitchen-home/kitchen-shape-4.png" alt="image">
    </div>
</div>