<div class="kindergarten-services-area bg-f7ebeb pt-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Our Services</span>
            <h2 class="font-weight-black">Best Services for Kids</h2>
            <p>Explore all of our courses and pick your suitable ones to enroll and start learning with us! We ensure that you will never regret it!</p>
        </div>
        <div class="services-slides">
            <owl-carousel-o [options]="servicesSlides">
                <ng-template carouselSlide>
                    <div class="single-kindergarten-services-box">
                        <img src="assets/img/box-shape1.png" alt="image">
                        <div class="content">
                            <div class="icon">
                                <i class="flaticon-guitar"></i>
                            </div>
                            <h3 class="font-weight-black">Music Training</h3>
                            <p>Lorem ipsum dolor sit amet, ipsum adipiscing elit elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua quis ipsum.</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-kindergarten-services-box">
                        <img src="assets/img/box-shape2.png" alt="image">
                        <div class="content">
                            <div class="icon">
                                <i class="flaticon-experience"></i>
                            </div>
                            <h3 class="font-weight-black">Sports Training</h3>
                            <p>Lorem ipsum dolor sit amet, ipsum adipiscing elit elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua quis ipsum.</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-kindergarten-services-box">
                        <img src="assets/img/box-shape3.png" alt="image">
                        <div class="content">
                            <div class="icon">
                                <i class="flaticon-artist"></i>
                            </div>
                            <h3 class="font-weight-black">Art Training</h3>
                            <p>Lorem ipsum dolor sit amet, ipsum adipiscing elit elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua quis ipsum.</p>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="single-kindergarten-services-box">
                        <img src="assets/img/box-shape2.png" alt="image">
                        <div class="content">
                            <div class="icon">
                                <i class="flaticon-translation"></i>
                            </div>
                            <h3 class="font-weight-black">Language Training</h3>
                            <p>Lorem ipsum dolor sit amet, ipsum adipiscing elit elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua quis ipsum.</p>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
        <div class="kids-kite-image">
            <img src="assets/img/kids-with-kite.png" alt="image">
        </div>
    </div>
    <div class="kindergarten-shape9"><img src="assets/img/kindergarten-shape/k-shape9.png" alt="image"></div>
    <div class="kindergarten-shape10"><img src="assets/img/kindergarten-shape/k-shape10.png" alt="image"></div>
</div>