<div class="why-choose-us-area ptb-100">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-image">
                    <img src="assets/img/why-choose-us.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="why-choose-us-content">
                    <span class="sub-title">WHY CHOOSE US</span>
                    <h2>BUILD GYM SKILLS WITH EXPERTS ANY TIME, ANYWHERE</h2>
                    <p>We understand better that online-based learning can make a significant change to reach students from all over the world! Giving options to learn better always can offer the best outcomes!</p>
                    <ul class="features-list">
                        <li><span><i class="flaticon-experience"></i> Skilled Trainers</span></li>
                        <li><span><i class="flaticon-time-left"></i> Afordable Courses</span></li>
                        <li><span><i class="flaticon-tutorials"></i> Efficient & Flexible</span></li>
                        <li><span><i class="flaticon-self-growth"></i> Lifetime Access</span></li>
                    </ul>
                    <a routerLink="/courses-1" class="default-btn"><i class="flaticon-user"></i>Join For Free<span></span></a>
                </div>
            </div>
        </div>
    </div>
</div>