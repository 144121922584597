import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import { API_ENDPOINT } from 'src/config/constants';
import { saveAs } from "file-saver";

@Component({
    selector: 'app-my-account',
    templateUrl: './my-account.html',
    styleUrls: ['./my-account.scss']
})
export class MyAccountComponent implements OnInit {
    myData:any;
    subbedCourses:any;
    constructor(public apiHttp:ApiHttpService,public router: Router,public loader:LoaderService) { }

    ngOnInit(): void {
        this.getAccData();
        this.getMyCourses();
    }

    ngAfterViewInit(){
        
    }

    getAccData()
    {
        this.loader.showLoader();
        this.apiHttp.get('users/myprofile/').subscribe(data=>{
            this.loader.hideLoader();
            this.myData = data['result'];
            console.log(this.myData);
            let {fname,lname,email,mobile}= this.myData;
            localStorage.setItem('userDetails', JSON.stringify({fname,lname,email,mobile}) );
        })
    } 

    fetchCourseName(id:string)
    {
        let resp = "";

        if((this.subbedCourses.filter(s=>s._id == id)).length > 0)
        {
            if(((this.subbedCourses.filter(s=>s._id == id))[0]['name']))
            {
                resp = ((this.subbedCourses.filter(s=>s._id == id))[0]['name'])
            }
        }
        return resp
    }

    qAction(c)
    {
        console.log(c);
        
        if(c.certificateDetail == null)
        {
            this.router.navigate(['/quiz-page/'+c['courseId']]);
        }
        else
        {

            // var form = document.createElement("form");
            // form.target = "view";
            // form.method = "POST";
            // form.action = API_ENDPOINT+'course/downloadcertificate';
            // var params = {
            //   "courseID": c['courseId'],
            //   "userID":this.myData['_id']
            // };
        
            // for (var i in params) {
            //     if (params.hasOwnProperty(i)) {
            //       var input = document.createElement('input');
            //       input.type = 'hidden';
            //       input.name = i;
            //       input.value = params[i];
            //       form.appendChild(input);
            //     }
            // }
        
            // document.body.appendChild(form);
            // form.submit();
            // window.open('', 'view');
            this.loader.showLoader();
            this.apiHttp.post('course/downloadcertificate',{
                    "courseID":c['courseId'],
                    "userID":this.myData['_id']
            }).subscribe(data => {
                this.loader.hideLoader();
                console.log(data['url']['short_url']);
            this.downloadFile(data['url']['short_url']);        
            })

        }
    }

    downloadFile(short) {
  
        const url = `https://4cqp4wpxpr5zkys3iafg2jevay0oocri.lambda-url.ap-south-1.on.aws/?url=${short}` 
        
            window.open(url, "_blank");
        }


    reAttemptQuiz(c)
    {
            this.router.navigate(['/quiz-page/'+c['courseId']]);
    }
    
    getMyCourses()
    {
        this.loader.showLoader();
        this.apiHttp.get('course/mycourses/').subscribe(data=>{
            this.loader.hideLoader();
            this.subbedCourses = data['result'];
            console.log(this.subbedCourses);
        })
    }

    goToViewCourse(courseid)
    {
        this.router.navigate(['/watch-course/'+courseid]);
    }

}