import { Component, OnInit } from '@angular/core';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { catchError } from 'rxjs/operators';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { throwError } from 'rxjs';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { LoaderService } from 'src/app/core/services/loader.service';


@Component({
  selector: 'app-profile-authentication',
  templateUrl: './profile-authentication.component.html',
  styleUrls: ['./profile-authentication.component.scss']
})
export class ProfileAuthenticationComponent implements OnInit {
  loginDetails = {
    uname:'',
    pass:''
  }

  showLogin=true;
  fpSubmitted = false;

  signUpDetails = {
    email:'',
    password:'',
    mobile:'',
    fname:'',
    lname:''
  }
  constructor(private router: Router,public apiHttp:ApiHttpService,public loader:LoaderService) { }

  ngOnInit(): void {
    let tokenDetails = JSON.parse(localStorage.getItem('userToken'));
    if (tokenDetails && tokenDetails.token && tokenDetails.token.length > 0) {
      this.router.navigate(['/my-account']);
      setTimeout(() => {  
        location.reload();
      }, 250);
    }
  }

  attemptLogin()
  {
    if(this.loginDetails.uname.length > 0 && this.loginDetails.pass.length > 0)
    {
      this.loader.showLoader();
      this.apiHttp.post('auth',{
        "email":this.loginDetails.uname,
        "password":this.loginDetails.pass
      }).pipe(catchError((err: any) => {
        this.loader.hideLoader();
        return this.errorHandler(err);  //<-- insert this 
      })).subscribe(data=>{
        this.loader.hideLoader();
          console.log(data);
          let resp:any = data;
          if(resp && resp.token && resp.token.length > 0)
          {
            
            Swal.fire({
              position: 'top-end',
              icon: 'success',
              title: 'Login Success',
              showConfirmButton: false,
              timer: 1500
            }).then(d=>{
              console.log('login success');
              localStorage.setItem('userToken', JSON.stringify({token:resp.token,expiry:resp.expiresIn,savedOn:Date.now()}));
              this.gotoLink('/my-account')
              setTimeout(() => {  
                location.reload();
              }, 250);
            })
          } 
        })
    }


  }

  attemptSignUp()
  {
    if(
      this.signUpDetails.fname.length > 0 && 
      this.signUpDetails.lname.length > 0  && 
      this.signUpDetails.password.length > 0  && 
      this.signUpDetails.email.length > 0  && 
      this.signUpDetails.mobile.length == 10 
      )
    {
      this.loader.showLoader();
      this.apiHttp.post('users',this.signUpDetails).pipe(catchError((err: any) => {
        this.loader.hideLoader();
        return this.signupErrorHandler(err);  //<-- insert this 
      })).subscribe(data=>{
        this.loader.hideLoader();
          console.log(data);
          let resp:any = data;
          if(resp && resp.success)
          {
            console.log('acc created successfully');
            this.loginDetails.uname = this.signUpDetails.email;
            this.loginDetails.pass = this.signUpDetails.password;
            this.attemptLogin();
          }
          else
          {
            console.log("Already reged");
            
          }
        },err1 => {
          console.log(err1)
        })
    }
  }

  forgotPass()
  {
    if(this.loginDetails.uname.length > 0)
    {
      this.loader.showLoader();
      this.apiHttp.post('auth/forgotpassword',{
        "email":this.loginDetails.uname
      }).pipe(catchError((err: any) => {
        this.loader.hideLoader();
        return this.errorHandler(err);  //<-- insert this 
      })).subscribe(data=>{
        this.loader.hideLoader();
          console.log(data);
          this.fpSubmitted = true;
            console.log('will send pass if email is found in our records');
            
        })
    }
  }

  errorHandler(error: HttpErrorResponse) {
    
    if(error.error.statusCode == 401)
    {
      console.log('error',error.error.message);
      Swal.fire({
        position: 'top-end',
        icon: 'error',
        title: 'Login Failed',
        text:'Invalid Credentials',
        showConfirmButton: false,
        timer: 1500
      })
    }
    return throwError(error.message || "server error.");
}

signupErrorHandler(error: HttpErrorResponse){
  console.log(error);
  if(error?.error?.message){
    Swal.fire({
      position: 'top-end',
      icon: 'error',
      title: error?.error?.message,
      text:'Registration Failed',
      showConfirmButton: false,
      timer: 3000
    })
  }
  return throwError(error.message || "server error.");
}

gotoLink(link:string)
{
  // add tokanbased checking
  this.router.navigate([link]);
  // add a validation
}

}
