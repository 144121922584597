import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent {

  @ViewChild('myModal', {static: false}) modal: ElementRef;

  @Input() questionsData: any;

  currQIndx = 0;

  selectedOpts = [];

  markSelection(indx,option)
  {
      // console.log(this.questionsData.quizData[indx],option);
      this.selectedOpts[indx] = option;
      console.log(this.selectedOpts);
      
  }

  open() {
    this.modal.nativeElement.style.display = 'block';
  }

  close() {
    this.modal.nativeElement.style.display = 'none';
  }

  next()
  {
    if(this.currQIndx < this.questionsData.quizData.length -1)
    {
      this.currQIndx +=1;
    }
  }

  prev()
  {
    if(this.currQIndx > 0)
    {
      this.currQIndx -=1;
    }
  }
  
  getOptAlpha(opIndx):string
  {
    return String.fromCharCode(opIndx+65)
  }
}