<div class="instructor-banner-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="instructor-banner-content">
                    <h1 class="el-messiri-font">Learn, Grow, Accomplish your goals</h1>
                    <p style="text-align: justify;">
                        Welcome to digital world learning by <b>Edufinpact Labs Pvt. Ltd.</b> Our team of experienced and
                        knowledgeable trainers have come together to deliver high-quality, practical, and engaging
                        training programs. <b>GYAAN</b> is a video based learning portal. 
                        <span *ngIf="!showMore" class="morelesstxt" (click)="toggleText()" >
                            Read more ↓
                        </span>
                        <span style="text-align: justify;" *ngIf="showMore">
                            Video-based learning has become
                            increasingly popular in recent years, as it allows learners to access information and training
                            in a more engaging and interactive way. <b>GYAAN</b> allows you to access the practical content in a
                            more simplified manner and helps you to learn about core finance anytime and from anywhere with
                            practical examples. There are specific modules required for skill development in finance which
                            can help you in your career to get industry ready.
                        </span>
                       
                    </p>
                    <span *ngIf="showMore">
                        <br/>
                        <p style="text-align: justify;">
                            There will be pre-recorded lectures which will provide you interactive learning and real example
                            based learning. Overall, <b>GYAAN</b> can be a powerful tool for acquiring new knowledge and skills,
                            and can help individuals and organizations stay up-to-date with the latest developments in their
                            field. Whether you're a student, a professional, or simply someone looking to expand your
                            horizons, video-based learning can provide a valuable and engaging way to learn and grow.
                        </p>
                        <span  class="morelesstxt" (click)="toggleText()" >
                            Read less ↑
                        </span>
                    </span>
   
                    <div class="banner-btn">
                        <a href="/#/profile-authentication" class="default-btn"><i class="flaticon-user"></i>Get Started
                            Now<span></span></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="instructor-banner-image">
                    <img src="assets/img/instructor-home/banner/main.png" alt="image">
                    <div class="banner-dot-shape">
                        <img src="assets/img/instructor-home/banner/dot-shape.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="instructor-banner-shape-1">
        <img src="assets/img/instructor-home/banner/shape1.png" alt="image">
    </div>
    <div class="instructor-banner-shape-2">
        <img src="assets/img/instructor-home/banner/shape2.png" alt="image">
    </div>
    <div class="instructor-banner-shape-3">
        <img src="assets/img/instructor-home/banner/shape3.png" alt="image">
    </div>
</div>