<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Blog Right Sidebar</li>
            </ul>
            <h2>Blog Right Sidebar</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="blog-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="row">
                    <div class="col-lg-12 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/single-blog-1" class="d-block">
                                    <img src="assets/img/blog/img1.jpg" alt="image">
                                </a>
                            </div>
                            <div class="post-content">
                                <a routerLink="/blog-4" class="category">Education</a>
                                <h3><a routerLink="/single-blog-1">The Beat Goes On: High School Choirs Improvise In The Age Of Coronavirus</a></h3>
                                <ul class="post-content-footer d-flex align-items-center">
                                    <li>
                                        <div class="post-author d-flex align-items-center">
                                            <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                                            <span>Alex Morgan</span>
                                        </div>
                                    </li>
                                    <li><i class='flaticon-calendar'></i> April 30, 2020</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/single-blog-1" class="d-block">
                                    <img src="assets/img/blog/img2.jpg" alt="image">
                                </a>
                            </div>
                            <div class="post-content">
                                <a routerLink="/blog-4" class="category">Online</a>
                                <h3><a routerLink="/single-blog-1">How Online Book Read-Alouds Can Help Students' Literacy and Connection During Social Distancing</a></h3>
                                <ul class="post-content-footer d-flex align-items-center">
                                    <li>
                                        <div class="post-author d-flex align-items-center">
                                            <img src="assets/img/user2.jpg" class="rounded-circle" alt="image">
                                            <span>Sarah Taylor</span>
                                        </div>
                                    </li>
                                    <li><i class='flaticon-calendar'></i> April 29, 2020</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/single-blog-1" class="d-block">
                                    <img src="assets/img/blog/img3.jpg" alt="image">
                                </a>
                            </div>
                            <div class="post-content">
                                <a routerLink="/blog-4" class="category">Learning</a>
                                <h3><a routerLink="/single-blog-1">How To Secure Remote Workers During The COVID-19 Outbreak</a></h3>
                                <ul class="post-content-footer d-flex align-items-center">
                                    <li>
                                        <div class="post-author d-flex align-items-center">
                                            <img src="assets/img/user3.jpg" class="rounded-circle" alt="image">
                                            <span>David Warner</span>
                                        </div>
                                    </li>
                                    <li><i class='flaticon-calendar'></i> April 28, 2020</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-6">
                        <div class="single-blog-post">
                            <div class="post-image">
                                <a routerLink="/single-blog-1" class="d-block">
                                    <img src="assets/img/blog/img7.jpg" alt="image">
                                </a>
                            </div>
                            <div class="post-content">
                                <a routerLink="/blog-4" class="category">Education</a>
                                <h3><a routerLink="/single-blog-1">What A Company Needs To Provide Employees For Effective Remote Work</a></h3>
                                <ul class="post-content-footer d-flex align-items-center">
                                    <li>
                                        <div class="post-author d-flex align-items-center">
                                            <img src="assets/img/user1.jpg" class="rounded-circle" alt="image">
                                            <span>Alex Morgan</span>
                                        </div>
                                    </li>
                                    <li><i class='flaticon-calendar'></i> April 30, 2020</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="pagination-area text-center">
                            <a routerLink="/blog-4" class="prev page-numbers"><i class='bx bx-chevrons-left'></i></a>
                            <span class="page-numbers current" aria-current="page">1</span>
                            <a routerLink="/blog-4" class="page-numbers">2</a>
                            <a routerLink="/blog-4" class="page-numbers">3</a>
                            <a routerLink="/blog-4" class="page-numbers">4</a>
                            <a routerLink="/blog-4" class="next page-numbers"><i class='bx bx-chevrons-right'></i></a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <div class="widget widget_search">
                        <h3 class="widget-title">Search</h3>
                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="bx bx-search-alt"></i></button>
                        </form>
                    </div>
                    <div class="widget widget_odemy_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>
                        <article class="item">
                            <a routerLink="/single-blog-1" class="thumb">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <span>June 10, 2020</span>
                                <h4 class="title usmall"><a routerLink="/single-blog-1">Ultimate Bali Guide + Where to stay in Bali 2020</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-blog-1" class="thumb">
                                <span class="fullimage cover bg2" role="img"></span>
                            </a>
                            <div class="info">
                                <span>June 21, 2020</span>
                                <h4 class="title usmall"><a routerLink="/single-blog-1">Live the Island life: 20 unique Islands to visit in 2020</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                        <article class="item">
                            <a routerLink="/single-blog-1" class="thumb">
                                <span class="fullimage cover bg3" role="img"></span>
                            </a>
                            <div class="info">
                                <span>June 30, 2020</span>
                                <h4 class="title usmall"><a routerLink="/single-blog-1">Best Places to Visit in Europe this Autumn & Winter</a></h4>
                            </div>
                            <div class="clear"></div>
                        </article>
                    </div>
                    <div class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>
                        <ul>
                            <li><a routerLink="/blog-4">Design <span class="post-count">(03)</span></a></li>
                            <li><a routerLink="/blog-4">Lifestyle <span class="post-count">(05)</span></a></li>
                            <li><a routerLink="/blog-4">Script <span class="post-count">(10)</span></a></li>
                            <li><a routerLink="/blog-4">Device <span class="post-count">(08)</span></a></li>
                            <li><a routerLink="/blog-4">Tips <span class="post-count">(01)</span></a></li>
                        </ul>
                    </div>
                    <div class="widget widget_tag_cloud">
                        <h3 class="widget-title">Popular Tags</h3>
                        <div class="tagcloud">
                            <a routerLink="/blog-4">Business <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-4">Design <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-4">Braike <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-4">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-4">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-4">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-4">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-4">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </div>
                </aside>
            </div>
        </div>
    </div>
</div>