<div class="container">
    <div class="section-title">
        <span class="sub-title">Course Advisor</span>
        <h2>Meet Our World-class Instructors</h2>
        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    </div>
    <div class="advisor-slides-two">
        <owl-carousel-o [options]="advisorSlidesTwo">
            <ng-template carouselSlide>
                <div class="single-advisor-item">
                    <div class="advisor-image">
                        <img src="assets/img/advisor/img4.jpg" alt="image">
                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                    <div class="advisor-content">
                        <h3><a routerLink="/profile">James Andy</a></h3>
                        <span>Project Management Expert</span>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-advisor-item">
                    <div class="advisor-image">
                        <img src="assets/img/advisor/img5.jpg" alt="image">
                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                    <div class="advisor-content">
                        <h3><a routerLink="/profile">Jassica Hische</a></h3>
                        <span>Illustrator Expert</span>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-advisor-item">
                    <div class="advisor-image">
                        <img src="assets/img/advisor/img6.jpg" alt="image">
                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                    <div class="advisor-content">
                        <h3><a routerLink="/profile">Alister Cock</a></h3>
                        <span>QA Project Expert</span>
                    </div>
                </div>
            </ng-template>
            <ng-template carouselSlide>
                <div class="single-advisor-item">
                    <div class="advisor-image">
                        <img src="assets/img/advisor/img7.jpg" alt="image">
                        <ul class="social-link">
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>
                    </div>
                    <div class="advisor-content">
                        <h3><a routerLink="/profile">Lina Ninja</a></h3>
                        <span>QA Project Expert</span>
                    </div>
                </div>
            </ng-template>
        </owl-carousel-o>
    </div>
</div>