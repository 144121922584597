import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    tokenDetails:any;
    isLoggedin= false;
    constructor() { }

    ngOnInit(): void {
        this.tokenDetails = JSON.parse(localStorage.getItem('userToken'));
        if (this.tokenDetails && this.tokenDetails.token && this.tokenDetails.token.length > 0) {
          this.isLoggedin = true;
        }
    }

    classApplied = false;
    toggleClass() {
        this.classApplied = !this.classApplied;
    }

    logOutAction()
    {

        Swal.fire({
            title: 'Do you want to logout ?',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            denyButtonText: `Cancel`,
          }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                localStorage.removeItem("userToken");
                location.reload();
                Swal.fire('Logged Out!', '', 'success')
            } else if (result.isDenied) {
              Swal.fire('Changes are not saved', '', 'info')
            }
          })



    }

}