<div class="health-coaching-feedback-area">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="health-coaching-feedback-image">
                    <img src="assets/img/woman.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="health-coaching-inner">
                    <h2 class="playfair-display-font">More Than 200,000 People Agree That We are Different</h2>
                    <div class="feedback-quote">
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        <div class="client-info">
                            <div class="d-flex justify-content-center align-items-center">
                                <img src="assets/img/user1.jpg" alt="image">
                                <div class="title">
                                    <h3>John Smith</h3>
                                    <span>Python Developer</span>
                                </div>
                            </div>
                        </div>
                        <div class="object1"><img src="assets/img/object1.png" alt="image"></div>
                        <div class="object2"><img src="assets/img/object2.png" alt="image"></div>
                        <div class="object3"><img src="assets/img/object3.png" alt="image"></div>
                        <div class="object4"><img src="assets/img/object4.png" alt="image"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>