<div class="health-services-area bg-fcf7f3 pt-100 pb-70" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Do not wait for Tomorrow!</span>
            <h2 class="playfair-display-font">You’ve tried dieting a million times. Now try something different.</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-health-services-box">
                    <div class="icon">
                        <i class="flaticon-yoga-2"></i>
                    </div>
                    <h3 class="playfair-display-font"><a routerLink="/courses-1">Daily Excersize</a></h3>
                    <p>Lorem ipsum dolor sit amet, conectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-health-services-box">
                    <div class="icon">
                        <i class="flaticon-yoga"></i>
                    </div>
                    <h3 class="playfair-display-font"><a routerLink="/courses-1">Find Your Balance</a></h3>
                    <p>Lorem ipsum dolor sit amet, conectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-health-services-box">
                    <div class="icon">
                        <i class="flaticon-lotus"></i>
                    </div>
                    <h3 class="playfair-display-font"><a routerLink="/courses-1">Personal Program</a></h3>
                    <p>Lorem ipsum dolor sit amet, conectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-health-services-box">
                    <div class="icon">
                        <i class="flaticon-tomato"></i>
                    </div>
                    <h3 class="playfair-display-font"><a routerLink="/courses-1">Natural Process</a></h3>
                    <p>Lorem ipsum dolor sit amet, conectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-health-services-box">
                    <div class="icon">
                        <i class="flaticon-yoga-1"></i>
                    </div>
                    <h3 class="playfair-display-font"><a routerLink="/courses-1">Immune System</a></h3>
                    <p>Lorem ipsum dolor sit amet, conectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-health-services-box">
                    <div class="icon">
                        <i class="flaticon-strawberry"></i>
                    </div>
                    <h3 class="playfair-display-font"><a routerLink="/courses-1">Gives You Energy</a></h3>
                    <p>Lorem ipsum dolor sit amet, conectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                </div>
            </div>
        </div>
    </div>
</div>