<app-language-school-banner></app-language-school-banner>

<app-language-school-courses></app-language-school-courses>

<app-language-school-about></app-language-school-about>

<app-feedback-with-bg></app-feedback-with-bg>

<app-language-school-top-courses></app-language-school-top-courses>

<div class="bg-fffaf3">
    <app-funfacts-style-one></app-funfacts-style-one>
</div>

<app-how-to-apply></app-how-to-apply>

<app-free-trial></app-free-trial>

<app-upcoming-events></app-upcoming-events>

<div class="subscribe-area bg-f9f9f9 ptb-100">
    <div class="container">
        <app-subscribe></app-subscribe>
    </div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape12.png" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape13.png" alt="image"></div>
    <div class="shape15"><img src="assets/img/shape14.png" alt="image"></div>
</div>

<div class="partner-area bg-fe4a55 ptb-70">
    <div class="container">
        <app-partner></app-partner>
    </div>
</div>