<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo"><img style="height: 80px;" src="assets/img/LogoTest.png" alt="logo"></a>
                    <p>Working to bring significant changes in online-based learning by doing extensive research for course curriculum preparation, student engagements, and looking forward to the flexible education!</p>
                    <ul class="social-link">
                        <li><a href="https://youtube.com/@InfiniteFinancialAcademy" class="d-block" target="_blank"><i class='bx bxl-youtube'></i></a></li>
                        <li><a href="https://g.co/kgs/Syo9tG" class="d-block" target="_blank"><i class='bx bxl-google'></i></a></li>
                        <li><a href="https://instagram.com/infinitefinancialacademy?igshid=YmMyMTA2M2Y=" class="d-block" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="https://www.linkedin.com/company/edufinpactlabspvtltd/" class="d-block" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Explore</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Home</a></li>
                        <!-- <li><a routerLink="/about-1">About</a></li> -->
                        <li><a routerLink="/allcourses">Courses</a></li>

                        <li><a routerLink="/terms_conditions">Terms Conditions</a></li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <!-- <h3>Resources</h3>
                    <ul class="footer-links-list">
                        <li><a routerLink="/">Student Success</a></li>
                        <li><a routerLink="/">Scholarships</a></li>
                        <li><a routerLink="/">For Business</a></li>
                        <li><a routerLink="/">Go Premium</a></li>
                        <li><a routerLink="/">Team Plans</a></li>
                    </ul> -->
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <h3>Address</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bx-map'></i>
                            Edufinpact Labs Pvt Ltd
                            Office 10/55, First Floor, 
                            Saidham Shopping Plaza, 
                            P. K. Road, Opp. St. Marys School, 
                            Mulund, Mumbai-400080
                            </li>
                        <li><i class='bx bx-phone-call'></i><a href="tel:+919819198758">+91 9819198758</a></li>
                        <li><i class='bx bx-envelope'></i><a href="mailto:hello@odemy.com">Infin@infinacademy.in</a></li>
                        <!-- <li><i class='bx bxs-inbox'></i><a href="tel:+918097395386">+91 8097395386</a></li> -->
                        <li><img class="waicc" src="assets/img/icoWA.png" ><a href="tel:+919284553559">+91 9284553559</a></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>© Made with ❤️ in India</p>
                </div>
                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                        <li><a routerLink="/terms_conditions">Terms & Conditions</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <div class="lines">
        <div class="line"></div>
        <div class="line"></div>
        <div class="line"></div>
    </div>
</footer>