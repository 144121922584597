import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-kindergarten',
    templateUrl: './kindergarten.component.html',
    styleUrls: ['./kindergarten.component.scss']
})
export class KindergartenComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}