<div #myModal class="container">
    <div class="content" style="border-radius: 5px;">
        <div *ngIf="questionsData && questionsData.quizData && questionsData.quizData.length > 0">
            <div  *ngFor="let q of questionsData.quizData;let qindex = index;">
                <div class="quesSection" *ngIf="qindex == currQIndx">
                    <div>
                        {{currQIndx+1}} / {{questionsData.quizData.length}}
                    </div>
                    <div class="que">
                        Q.{{qindex+1}}) {{q.questionText}}
                    </div>

                    <div class="opts">
                        <div (click)="markSelection(currQIndx,ops)" [ngClass]="(q.correctOption.includes(ops) && q.correctOption.includes(selectedOpts[currQIndx])) ? 'rightotp' : 
                        (!q.correctOption.includes(selectedOpts[currQIndx]) && selectedOpts[currQIndx] && selectedOpts[currQIndx] == ops) ? 'selectedd': 'oneopt'"  *ngFor="let ops of q.options;let opIndx=index">
                            {{getOptAlpha(opIndx)}}. {{ops}}
                        </div>
                        <div style="text-transform: capitalize;" *ngIf=" q.correctOption.includes(selectedOpts[currQIndx]) ">
                            ✅ {{selectedOpts[currQIndx]}} : Correct
                        </div>
                        <div style="text-transform: capitalize;margin-left: 15px;"  *ngIf="!q.correctOption.includes(selectedOpts[currQIndx]) && selectedOpts[currQIndx]">
                            ❌ {{selectedOpts[currQIndx]}} : Wrong Selection
                    </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="btnss" style="display: flex;flex-direction: row;">
            <a *ngIf="currQIndx > 0" style="cursor:pointer;padding-left: 25px;background:#1c75db" (click)="prev()"
                class="default-btn">Prev</a>

                <a style="cursor:pointer;padding-left: 25px;background:#1c75db" (click)="close()"
                class="default-btn">Close</a>

                <a *ngIf="currQIndx < questionsData.quizData.length - 1" style="cursor:pointer;padding-left: 25px;background:#1c75db;" (click)="next()"
                class="default-btn">Next</a>
        </div>

    </div>
</div>