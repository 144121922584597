import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kitchen-coaching',
  templateUrl: './kitchen-coaching.component.html',
  styleUrls: ['./kitchen-coaching.component.scss']
})
export class KitchenCoachingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
