<app-gym-coaching-banner></app-gym-coaching-banner>

<app-featured></app-featured>

<app-why-choose-us></app-why-choose-us>

<app-gym-courses></app-gym-courses>

<app-funfacts-style-three></app-funfacts-style-three>

<app-feedback-style-three></app-feedback-style-three>

<app-expert-trainer></app-expert-trainer>

<app-app-download></app-app-download>

<app-gym-blog></app-gym-blog>

<app-online-platform></app-online-platform>