<div class="navbar-area" ngStickyNav stickyClass="sticky-box-shadow" ngStickyNav appNoRightClick>
    <div class="container-fluid">
        <nav class="navbar navbar-expand-lg navbar-light bg-light" [class.active]="classApplied">
            <a class="navbar-brand" routerLink="/"><img style="height: 80px;" src="assets/img/LogoTest.png" alt="logo"></a>
            <button class="navbar-toggler" type="button" (click)="toggleClass()">
                <span class="burger-menu">
                    <span class="top-bar"></span>
                    <span class="middle-bar"></span>
                    <span class="bottom-bar"></span>
                </span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
                <!-- <form class="search-box">
                    <input type="text" class="input-search" placeholder="Search for anything">
                    <button type="submit"><i class="flaticon-search"></i></button>
                </form> -->
                <ul class="navbar-nav">
                    <li class="nav-item"><a routerLink="/" class="nav-link">Home </a>
                    </li>

                    <li class="nav-item megamenu"><a routerLink="/allcourses" class="nav-link">Courses</a>
                    </li>
      
                    <!-- <li class="nav-item"><a routerLink="/about-1" class="nav-link">About Us </a>
                    </li>
                    <li class="nav-item"><a routerLink="/contact" class="nav-link"> Contact Us</a>
                    </li> -->

                    <li *ngIf="!isLoggedin" class="nav-item"><a routerLink="/profile-authentication" class="nav-link"> Login/Signup</a>
                    </li>

                    <li *ngIf="isLoggedin" class="nav-item"><a routerLink="/my-account" class="nav-link"> My Account</a>
                    </li>

                    <li *ngIf="isLoggedin"  class="nav-item"><a (click)="logOutAction()" class="nav-link"> Logout</a>
                    </li>

                </ul>
                <ul class="navbar-nav for-responsive">
                    <li class="nav-item"><a routerLink="/" class="nav-link">Home </a>
                    </li>
                    <li class="nav-item megamenu"><a routerLink="/allcourses" class="nav-link">Courses</a>
                    </li>
      
                    <!-- <li class="nav-item"><a routerLink="/about-1" class="nav-link">About Us </a>
                    </li>
                    <li class="nav-item"><a routerLink="/contact" class="nav-link"> Contact Us</a> 
                    </li> -->
                    <li *ngIf="!isLoggedin" class="nav-item"><a routerLink="/profile-authentication" class="nav-link">  Login/Signup</a>
                    </li>

                    <li *ngIf="isLoggedin" class="nav-item"><a routerLink="/my-account" class="nav-link"> My Account</a>
                    </li>

                    <li *ngIf="isLoggedin"  class="nav-item"><a (click)="logOutAction()" class="nav-link"> Logout</a>
                    </li>

                </ul>
            </div>
          
        </nav>
    </div>
</div>