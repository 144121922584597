<div class="testimonials-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Testimonials</span>
            <h2>Our Learners Feedback</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="testimonials-slides-two">
            <owl-carousel-o [options]="testimonialsSlidesTwo">
                <ng-template carouselSlide>
                    <div class="testimonials-item">
                        <div class="row align-items-center">
                            <div class="col-lg-8 col-md-12">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                <h3>John Smith</h3>
                                <span>Python Developer</span>
                            </div>
                            <div class="col-lg-4 col-md-12 text-center">
                                <img src="assets/img/user7.png" alt="image">
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="testimonials-item">
                        <div class="row align-items-center">
                            <div class="col-lg-8 col-md-12">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                <h3>David Malan</h3>
                                <span>Developer</span>
                            </div>
                            <div class="col-lg-4 col-md-12 text-center">
                                <img src="assets/img/user9.png" alt="image">
                            </div>
                        </div>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="testimonials-item">
                        <div class="row align-items-center">
                            <div class="col-lg-8 col-md-12">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                <h3>Sarah Taylor</h3>
                                <span>PHP Developer</span>
                            </div>
                            <div class="col-lg-4 col-md-12 text-center">
                                <img src="assets/img/user8.png" alt="image">
                            </div>
                        </div>
                    </div>
                </ng-template>
            </owl-carousel-o>
        </div>
    </div>
</div>