import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-features-style-three',
  templateUrl: './features-style-three.component.html',
  styleUrls: ['./features-style-three.component.scss']
})
export class FeaturesStyleThreeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
