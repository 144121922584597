import { Injectable } from '@angular/core'; 
import { HttpClient, HttpHeaders } from '@angular/common/http'; 
import { API_ENDPOINT } from '../../../config/constants';
import { map } from 'rxjs/operators';

@Injectable() 
export class ApiHttpService { 
constructor( 
// Angular Modules 
private http: HttpClient 
) {

 } 

public get(url: string, options?: any) { 
  if(localStorage.getItem('userToken'))
  {
      let {token} = JSON.parse(localStorage.getItem('userToken')); 
      const httpOptions = {
          headers: new HttpHeaders(
            {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ` + token,
            }
          )
        };

        return this.http.get(API_ENDPOINT+url,httpOptions); 
      }
  else
return this.http.get(API_ENDPOINT+url, options); 
} 

public post(url: string, data: any, options?: any) { 
    if(localStorage.getItem('userToken'))
    {
        let {token} = JSON.parse(localStorage.getItem('userToken')); 
        const httpOptions = {
            headers: new HttpHeaders(
              {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ` + token,
              }
            )
          };
return this.http.post(API_ENDPOINT+url, data, httpOptions); 

    }
    else
return this.http.post(API_ENDPOINT+url, data, options); 
} 

public put(url: string, data: any, options?: any) { 
return this.http.put(API_ENDPOINT+url, data, options); 
} 
public delete(url: string, options?: any) { 
return this.http.delete(API_ENDPOINT+url, options); 
} 

async downloadLargeFile(url:string): Promise<ArrayBuffer> {
 return await this.http.get(url).pipe(map((file:ArrayBuffer) => {
return file;
})).toPromise()
}
}