<app-oa-banner></app-oa-banner>

<app-instructor-quote></app-instructor-quote>

<app-features></app-features>

<app-oa-courses></app-oa-courses>

<app-funfacts-feedback></app-funfacts-feedback>

<div class="online-art-home-with-large-bg">
    <app-oa-events></app-oa-events>

    <app-subscribe></app-subscribe>

    <app-blog></app-blog>
</div>