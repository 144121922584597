<div class="motivation-events-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="motivation-events-content">
                    <span class="sub-title">COACHING PROGRAM</span>
                    <h2 class="jost-font">Our Upcoming Events</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div class="motivation-events-slides">
                        <owl-carousel-o [options]="motivationEventsSlides">
                            <ng-template carouselSlide>
                                <div class="motivation-item">
                                    <div class="single-motivation-events-box">
                                        <span class="location"><i class='bx bx-map'></i> New York</span>
                                        <h3 class="jost-font"><a routerLink="/single-events">Fundamentals of Self Leadership</a></h3>
                                        <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy Ticket<span></span></a>
                                        <div class="date">
                                            <div class="div">
                                                18
                                                <span>March</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="single-motivation-events-box">
                                        <span class="location"><i class='bx bx-map'></i> Washington DC</span>
                                        <h3 class="jost-font"><a routerLink="/single-events">Network of Professional</a></h3>
                                        <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy Ticket<span></span></a>
                                        <div class="date">
                                            <div class="div">
                                                25
                                                <span>April</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="single-motivation-events-box">
                                        <span class="location"><i class='bx bx-map'></i> Athens, Greece</span>
                                        <h3 class="jost-font"><a routerLink="/single-events">Conference on Literacy Teaching</a></h3>
                                        <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy Ticket<span></span></a>
                                        <div class="date">
                                            <div class="div">
                                                29
                                                <span>March</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="motivation-item">
                                    <div class="single-motivation-events-box">
                                        <span class="location"><i class='bx bx-map'></i> New York</span>
                                        <h3 class="jost-font"><a routerLink="/single-events">Fundamentals of Self Leadership</a></h3>
                                        <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy Ticket<span></span></a>
                                        <div class="date">
                                            <div class="div">
                                                18
                                                <span>March</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="single-motivation-events-box">
                                        <span class="location"><i class='bx bx-map'></i> Washington DC</span>
                                        <h3 class="jost-font"><a routerLink="/single-events">Network of Professional</a></h3>
                                        <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy Ticket<span></span></a>
                                        <div class="date">
                                            <div class="div">
                                                25
                                                <span>April</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="single-motivation-events-box">
                                        <span class="location"><i class='bx bx-map'></i> Athens, Greece</span>
                                        <h3 class="jost-font"><a routerLink="/single-events">Conference on Literacy Teaching</a></h3>
                                        <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy Ticket<span></span></a>
                                        <div class="date">
                                            <div class="div">
                                                29
                                                <span>March</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                            <ng-template carouselSlide>
                                <div class="motivation-item">
                                    <div class="single-motivation-events-box">
                                        <span class="location"><i class='bx bx-map'></i> New York</span>
                                        <h3 class="jost-font"><a routerLink="/single-events">Fundamentals of Self Leadership</a></h3>
                                        <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy Ticket<span></span></a>
                                        <div class="date">
                                            <div class="div">
                                                18
                                                <span>March</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="single-motivation-events-box">
                                        <span class="location"><i class='bx bx-map'></i> Washington DC</span>
                                        <h3 class="jost-font"><a routerLink="/single-events">Network of Professional</a></h3>
                                        <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy Ticket<span></span></a>
                                        <div class="date">
                                            <div class="div">
                                                25
                                                <span>April</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="single-motivation-events-box">
                                        <span class="location"><i class='bx bx-map'></i> Athens, Greece</span>
                                        <h3 class="jost-font"><a routerLink="/single-events">Conference on Literacy Teaching</a></h3>
                                        <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i> Buy Ticket<span></span></a>
                                        <div class="date">
                                            <div class="div">
                                                29
                                                <span>March</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </owl-carousel-o>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="motivation-events-image">
                    <img src="assets/img/motivation-home/motivation-events.jpg" alt="image">
                    <div class="motivation-shape-6">
                        <img src="assets/img/motivation-home/motivation-shape-6.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>