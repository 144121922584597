<div class="subscribe-area-two" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="subscribe-image">
                    <img src="assets/img/subscribe-img2.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="subscribe-content">
                    <span class="sub-title">Go At Your Own Pace</span>
                    <h2 class="playfair-display-font">Subscribe To Our Newsletter</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <form class="newsletter-form">
                        <input type="text" class="input-newsletter" placeholder="Enter your email address" name="EMAIL" required autocomplete="off">
                        <button type="submit" class="default-btn"><i class="flaticon-user"></i>Subscribe Now<span></span></button>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape12.png" alt="image"></div>
</div>