<div class="outQz">
    <div class="innQz">
        <div style="text-align: center;
        margin-bottom: 10px;
        font-weight: bold;
        color: red;">
            Do NOT refresh page or close the tab while attempting Exam Progress and attempt will be lost
        </div>
        <div>
          Test For Course : <strong>{{courseName}}</strong> 
          <span *ngIf="countDownTimerMin >= 0" style="float: right;
          color: red;">
            Time Remaining : {{paddZero(countDownTimerMin)}}:{{paddZero(countDownTimerSec)}}
          </span>
        </div>
        <br/>
    
        <div>
    
            <div *ngFor="let q of quizData;let qIndx = index" class="queSection" >
                    <div class="queTxt">
                        Q. {{qIndx+1}})  <strong style="text-transform: capitalize;" > {{q.questionText}} </strong> 
                    </div>
    
                    <div class="optSection" >
                            <div [ngStyle]="{'background': (o.isSelected) ? 'lightgreen' : '#ffffff'}" (click)="makeSelection(qIndx,oIndx)" *ngFor="let o of q.options;let oIndx = index" class="opt">
                                <div style="display: flex;flex-direction: row;">
                                    <span class="optNum">
                                        {{oIndx+1}}  
                                    </span>
                                    <span style="padding: 5px;">
                                        {{o.option}}  
                                    </span>
                                </div>
                              
                              
                            </div>
                    </div>
            </div>
    
        </div>
    
        <div>
            <br/>
            <button class="subBtn" (click)="validateSelection()" > Submit Quiz</button>
        </div>
    </div>

</div>