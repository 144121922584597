<div class="mncmpnt">
    <div *ngIf="courseDetails.studyMaterial?.length > 0" class="accottr">
       
        <div class="acchdr" style="border-bottom: solid #FE4A55 3px;" (click)="isPdfExapanded=!isPdfExapanded" >
            <div >
                Study Resources (Pdfs)
            </div>
          
            <div class="accdrpdwn">
               
<svg [ngClass]="isPdfExapanded ? 'upchev' : 'downchev'"  width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 13L12 18L17 13M7 6L12 11L17 6" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
            </div>
        </div>

        <div class="tglldiv" *ngIf="isPdfExapanded" >
            <div (click)="openPDF(p)" *ngFor="let p of courseDetails.studyMaterial" [ngClass]="false ? 'tgllitm' : 'tgllitmSelected'">
                {{p.name}}
            </div>
        </div>

    </div>
    <div class="ccdrtxt">
        Course content
    </div>

    <div *ngFor="let vm of modifiedList;let vmindx=index;" class="accottr">
        <div class="acchdr" (click)="expandModule(vmindx)" >
            <div>
                Module {{vmindx+1}} : {{vm.moduleTitle}}
            </div>
          
            <div class="accdrpdwn">
               
<svg [ngClass]="vm.isExpanded ? 'upchev' : 'downchev'"  width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7 13L12 18L17 13M7 6L12 11L17 6" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
            </div>
        </div>
        <div class="tglldiv" *ngIf="vm.isExpanded" >
            <div (click)="selectVideo(vmindx,vindx)" *ngFor="let video of vm.videoList;let vindx = index;" [ngClass]="!video.isSelected ? 'tgllitm' : 'tgllitmSelected'">
                {{vmindx+1}}.{{vindx+1}}. {{video.videoTitle}}
            </div>
        </div>
    </div>
    <br/>
    <div style="margin-left: 20px;" >
        <a style="cursor:pointer;"  (click)="gotoQuiz()" class="default-btn"><i class="flaticon-tag"></i>Take Quiz<span></span></a>
    </div>
</div>


   