<div class="about-area ptb-100 bg-f9fbff">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <img src="assets/img/about-img12.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">DISTANCE LEARNING</span>
                    <h2>Develop Your Skills, Learn Something New, and Grow Your Skills From Anywhere in the World!</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <ul class="features-list">
                        <li><span><i class="flaticon-experience"></i> Expert Instruction</span></li>
                        <li><span><i class="flaticon-time-left"></i> Lifetime Access</span></li>
                        <li><span><i class="flaticon-tutorials"></i> Remote Learning</span></li>
                        <li><span><i class="flaticon-self-growth"></i> Self Development</span></li>
                    </ul>
                    <a routerLink="/courses-1" class="default-btn"><i class="flaticon-user"></i>View All Courses<span></span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="bulb"><img src="assets/img/bulb.png" alt="image"></div>
</div>