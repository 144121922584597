import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { LoaderService } from 'src/app/core/services/loader.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-quiz-page',
  templateUrl: './quiz-page.component.html',
  styleUrls: ['./quiz-page.component.scss']
})
export class QuizPage implements OnInit {
  courseID: any;
  courseName: string = "";
  quizData: any;
  countDownTimerMin = 59;
  countDownTimerSec = 59;
  cdInterval;
  isQuizSubmitted = false;
  constructor(private route: ActivatedRoute, public apiHttp: ApiHttpService, public router: Router, public loader: LoaderService) { }

  ngOnInit(): void {
    this.loader.showLoader();
    this.route.queryParams.subscribe((qp) => {
      this.loader.hideLoader();
      this.courseID = this.route.snapshot.params['type'];
      this.getQuizData(this.courseID);
    });
    this.cdInterval = setInterval(() => {
      this.countDownTimerSec--;
      if (this.countDownTimerSec == 0) {
        this.countDownTimerMin--;
        this.countDownTimerSec = 59;
      }
      if (this.countDownTimerMin < 0) {
        console.log("time up");
        clearInterval(this.cdInterval);
        if (!this.isQuizSubmitted) {
          this.submitWithEmptyResponse();
          Swal.fire({
            position: 'top-end',
            icon: 'success',
            title: 'Time Up - Auto Submitting Marked Answers',
            showConfirmButton: false,
            timer: 4500
          })
        }

      }
    }, 1000);

  }

  getQuizData(courseID) {
    this.loader.showLoader();
    try {
      this.apiHttp.get('course/getquiz/' + courseID).subscribe(data => {
        this.loader.hideLoader();
        if(data['success'])
        {
          if (Object.keys(data['result']).length === 0) {
            Swal.fire({
              position: 'top-end',
              icon: 'error',
              title: 'Quiz not available for this Course yet',
              showConfirmButton: false,
              timer: 3500
            }).then(() => {
              this.router.navigate(['/my-account/']);
            })
          }
          else {
            this.courseName = data['result']['courseDetails']['name'];
            this.quizData = (data['result']['courseDetails']['quizData']).map((q: any) => {
              return {
                questionText: q.questionText,
                questionSequence: q.questionSequence,
                options: q.options.map((o: any) => {
                  return {
                    option: o,
                    isSelected: false
                  }
                })
              }
            });
            console.log(this.quizData);
          }
        }
        else
        {
          Swal.fire({
            position: 'top-end',
            icon: 'error',
            title: 'Quiz Attempts Exausted',
            showConfirmButton: false,
            timer: 3500
          }).then(() => {
            this.router.navigate(['/my-account/']);
          })
        }
      })
    } catch (error) {
      this.loader.hideLoader();
    }

  }

  makeSelection(qIndx: number, oIndx: number) {
    for (let i = 0; i < this.quizData[qIndx]['options'].length; i++) {
      if (i == oIndx)
        this.quizData[qIndx]['options'][i]['isSelected'] = !this.quizData[qIndx]['options'][i]['isSelected']
      else
        this.quizData[qIndx]['options'][i]['isSelected'] = false;
    }
  }

  validateSelection() {
    let allGood = true;
    for (let i = 0; i < this.quizData.length; i++) {
      if (!((this.quizData[i]['options']).some(o => o.isSelected === true))) {
        allGood = false;
        break;
      }
    }
    console.log("allGood", allGood);
    if (allGood) {
      let reqBody = this.quizData.map(q => {
        return {
          questionText: q.questionText,
          answer: ((((q.options).filter(a => a.isSelected == true))).map(s => s.option))[0]
        }
      })
      this.loader.showLoader();
      this.apiHttp.post('course/answerQuiz/', { courseID: this.courseID, quizData: reqBody }).subscribe(data => {
        this.loader.hideLoader();
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Responses Submitted Successfully,you can view result in My Account Section',
          showConfirmButton: false,
          timer: 5000
        }).then(() => {
          this.router.navigate(['/my-account']);
        })
      })
    }
    else {
      // Swal.fire({
      //   position: 'top-end',
      //   icon: 'error',
      //   title: 'Please Select Answer to all Questions',
      //   showConfirmButton: false,
      //   timer: 1500
      // })

      Swal.fire({
        title: 'All Answers Not Marked,Do you still want to submit ?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        denyButtonText: `Cancel`,
      }).then((result) => {
        if (result.isConfirmed) {
          if (!this.isQuizSubmitted) {
            this.submitWithEmptyResponse();
          }
        }
      })

    }

  }

  submitWithEmptyResponse() {
    let reqBody = this.quizData.map(q => {
      return {
        questionText: q.questionText,
        answer: (((((q.options).filter(a => a.isSelected == true))).map(s => s.option))[0]) || null
      }
    })
    console.log("reqBody", reqBody);
    this.loader.showLoader();
    this.apiHttp.post('course/answerQuiz/', { courseID: this.courseID, quizData: reqBody }).subscribe(data => {
      this.loader.hideLoader();
      Swal.fire({
        position: 'top-end',
        icon: 'success',
        title: 'Responses Submitted Successfully,you can view result in My Account Section',
        showConfirmButton: false,
        timer: 5000
      }).then(() => {
        this.router.navigate(['/my-account']);
      })
    })
  }

  paddZero(inp) {
    return (String(inp).length == 1) ? `0${inp}` : inp
  }

}