<app-online-training-banner></app-online-training-banner>

<app-online-training-courses></app-online-training-courses>

<div class="features-area pt-100 pb-70">
    <app-features-style-two></app-features-style-two>
</div>

<app-online-training-about></app-online-training-about>

<app-feedback></app-feedback>

<app-top-categories></app-top-categories>

<div class="bg-fffaf3">
    <app-funfacts-style-one></app-funfacts-style-one>
</div>

<div class="advisor-area bg-f9f9f9 pt-100">
    <app-instructor-style-two></app-instructor-style-two>
</div>

<div class="premium-access-area bg-f9f9f9 ptb-100">
    <app-premium-access></app-premium-access>
    <div class="shape3"><img src="assets/img/shape3.png" alt="image"></div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape7.png" alt="image"></div>
</div>

<app-online-training-blog></app-online-training-blog>

<div class="subscribe-area bg-f9f9f9 ptb-100">
    <div class="container">
        <app-subscribe></app-subscribe>
    </div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape12.png" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape13.png" alt="image"></div>
    <div class="shape15"><img src="assets/img/shape14.png" alt="image"></div>
</div>

<div class="partner-area ptb-70">
    <div class="container">
        <app-partner></app-partner>
    </div>
</div>