<div class="experience-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="experience-content">
                    <div class="shape"><img src="assets/img/fruits.png" alt="image"></div>
                    <span class="sub-title">Join our World Famous Yoga Teacher Training Course... Online!</span>
                    <h2 class="playfair-display-font">This Isn't a Diet, It's a Lifestyle</h2>
                    <p>XYZ training programs can bring you a super exciting experience of learning through online! You never face any negative experience while enjoying your classes virtually by sitting in your comfort zone. Our flexible learning initiatives will help you to learn better and quicker than the traditional ways of learning skills.</p>
                    <ul class="features-list">
                        <li><i class="bx bx-check"></i> Vegetable Intake</li>
                        <li><i class="bx bx-check"></i> An Apple a Day</li>
                        <li><i class="bx bx-check"></i> Good Nutrition</li>
                        <li><i class="bx bx-check"></i> Our Principles</li>
                        <li><i class="bx bx-check"></i> Healthy Life</li>
                        <li><i class="bx bx-check"></i> Personalized Plan</li>
                    </ul>
                    <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i>Get Started Now<span></span></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="experience-img">
                    <img src="assets/img/experience-img2.jpg" alt="image">
                    <span class="title"><span>15</span> Years of Experience</span>
                </div>
            </div>
        </div>
    </div>
    <div class="experience-shape1"><img src="assets/img/experience-shape1.png" alt="image"></div>
    <div class="experience-shape2"><img src="assets/img/experience-shape2.png" alt="image"></div>
</div>