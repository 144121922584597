<div class="online-art-banner-area" style="background-image: url(assets/img/online-art/banner/banner-bg.jpg);">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-12">
                <div class="online-art-banner-image">
                    <img src="assets/img/online-art/banner/main1.png" alt="banner-image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="online-art-banner-content">
                    <h1>Express your thoughts by Drawing and Coloring</h1>
                    <p>Lorem ipsum dolor sit amet consectetur adipiscing elit sed do eiusmod tempor incididunt ut labore et dolore magna aliqua quis ipsum suspendisse.</p>
                    <div class="banner-btn">
                        <a routerLink="/courses-1" class="default-btn"><i class="flaticon-user"></i>Find Courses<span></span></a>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-12">
                <div class="online-art-banner-wrap-image">
                    <img src="assets/img/online-art/banner/main2.jpg" alt="banner-image">
                </div>
            </div>
        </div>
    </div>
    <div class="online-art-shape-1">
        <img src="assets/img/online-art/banner/shape.png" alt="shape">
    </div>
</div>