import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-membership-levels',
  templateUrl: './membership-levels.component.html',
  styleUrls: ['./membership-levels.component.scss']
})
export class MembershipLevelsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
