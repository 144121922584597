import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-health-coaching-banner',
  templateUrl: './health-coaching-banner.component.html',
  styleUrls: ['./health-coaching-banner.component.scss']
})
export class HealthCoachingBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
