import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-si-banner',
  templateUrl: './si-banner.component.html',
  styleUrls: ['./si-banner.component.scss']
})
export class SiBannerComponent implements OnInit {
  showMore = false;
  constructor() { }

  ngOnInit(): void {
  }

  toggleText()
  {
      this.showMore = !this.showMore;
  }

}
