<div class="health-coaching-banner-area">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="health-coaching-banner-image">
                    <img src="assets/img/woman.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="health-coaching-banner-content">
                    <h1 class="playfair-display-font">Welcome, <br>I am Amada, <br>Your Nutritionist</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div class="btn-box">
                        <div class="d-flex align-items-center">
                            <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i>Join For Free<span></span></a>
                            <img src="assets/img/signature.png" class="signature" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="health-coaching-shape1"><img src="assets/img/health-coaching-shape1.png" alt="image"></div>
    <div class="health-coaching-shape2"><img src="assets/img/health-coaching-shape2.png" alt="image"></div>
    <div class="health-coaching-shape3"><img src="assets/img/health-coaching-shape3.png" alt="image"></div>
    <div class="health-coaching-shape4"><img src="assets/img/health-coaching-shape4.png" alt="image"></div>
    <div class="health-coaching-shape5"><img src="assets/img/health-coaching-shape5.png" alt="image"></div>
    <div class="health-coaching-shape6"><img src="assets/img/health-coaching-shape6.png" alt="image"></div>
    <div class="health-coaching-shape7"><img src="assets/img/health-coaching-shape7.png" alt="image"></div>
    <div class="divider"></div>
</div>