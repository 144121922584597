<div class="motivation-banner-area">
    <div class="container">
        <ul class="social-links">
            <li><a href="#" target="_blank">Linkedin</a></li>
            <li><a href="#" target="_blank">Facebook</a></li>
            <li><a href="#" target="_blank">Instagram</a></li>
            <li><a href="#" target="_blank">Pjostest</a></li>
        </ul>
        <div class="row align-items-center">
            <div class="col-lg-7 col-md-12">
                <div class="motivation-banner-content">
                    <span class="sub-title">I am John Doe</span>
                    <h1 class="jost-font">Opportunities Don't Happen, You Create Them</h1>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua quis ipsum suspendisse.</p>
                    <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i>Get My Free Book<span></span></a>
                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="motivation-banner-image">
                    <img src="assets/img/motivation-home/motivation-man.png" alt="business">
                </div>
            </div>
        </div>
    </div>
    <div class="motivation-shape-1">
        <img src="assets/img/motivation-home/motivation-shape-1.png" alt="image">
    </div>
    <div class="motivation-shape-2">
        <img src="assets/img/motivation-home/motivation-shape-2.png" alt="image">
    </div>
    <div class="motivation-shape-3">
        <img src="assets/img/motivation-home/motivation-shape-3.png" alt="image">
    </div>
</div>