<app-kitchen-coaching-banner></app-kitchen-coaching-banner>

<app-features-style-five></app-features-style-five>

<app-kitchen-coaching-about></app-kitchen-coaching-about>

<div class="partner-area pb-100">
    <div class="container">
        <app-partner></app-partner>
    </div>
</div>

<app-download-book></app-download-book>

<app-kitchen-coaching-courses></app-kitchen-coaching-courses>

<app-kitchen-feedback-funfacts></app-kitchen-feedback-funfacts>

<app-kitchen-coaching-events></app-kitchen-coaching-events>

<app-my-newsletter></app-my-newsletter>

<app-get-started></app-get-started>