<div class="kitchen-book-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-6">
                <div class="kitchen-book-image">
                    <img src="assets/img/kitchen-home/book.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-6">
                <div class="kitchen-book-content">
                    <span class="sub-title">Download Kitchen Book</span>
                    <h2 class="font-weight-black">Passion for cooking can turn a profession any time</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div class="book-btn">
                        <a routerLink="/kitchen-coaching" class="default-btn"><i class="flaticon-user"></i>Download Book<span></span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>