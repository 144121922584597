<div class="kitchen-events-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Upcoming Events</span>
            <h2 class="font-weight-black">Check Out Our Latest Events</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-kitchen-events-box">
                    <div class="events-image">
                        <a routerLink="/single-events" class="d-block">
                            <img src="assets/img/kitchen-home/events-1.jpg" alt="image">
                        </a>
                    </div>
                    <div class="events-content">
                        <a routerLink="/events" class="category">Coocking</a>
                        <h3 class="font-weight-black"><a routerLink="/single-events">NYC Wine & Food Festival</a></h3>
                        <ul class="events-content-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-calendar'></i> April 30, 2021</li>
                            <li><i class='bx bx-map'></i> Vancover, Canada</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-kitchen-events-box">
                    <div class="events-image">
                        <a routerLink="/single-events" class="d-block">
                            <img src="assets/img/kitchen-home/events-2.jpg" alt="image">
                        </a>
                    </div>
                    <div class="events-content">
                        <a routerLink="/events" class="category">Coocking</a>
                        <h3 class="font-weight-black"><a routerLink="/single-events">Epcot International Food & Wine</a></h3>
                        <ul class="events-content-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-calendar'></i> April 30, 2021</li>
                            <li><i class='bx bx-map'></i> Vancover, Canada</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-kitchen-events-box">
                    <div class="events-image">
                        <a routerLink="/single-events" class="d-block">
                            <img src="assets/img/kitchen-home/events-3.jpg" alt="image">
                        </a>
                    </div>
                    <div class="events-content">
                        <a routerLink="/events" class="category">Coocking</a>
                        <h3 class="font-weight-black"><a routerLink="/single-events">New Orleans Wine & Food</a></h3>
                        <ul class="events-content-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-calendar'></i> April 30, 2021</li>
                            <li><i class='bx bx-map'></i> Vancover, Canada</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="kitchen-events-info">
                    <p>Get into details now?​ <a routerLink="/events">View all events</a></p>
                </div>
            </div>
        </div>
    </div>
</div>