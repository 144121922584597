<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li><a routerLink="/">Courses</a></li>
                <li *ngIf="courseDetails && courseDetails.name" >{{courseDetails.name}}</li>
            </ul>
            <h2 *ngIf="courseDetails && courseDetails.name">{{courseDetails.name}}</h2>
            <div class="rating">
      
                <div class="rating-count">
                    Instructor : <span *ngIf="courseDetails && courseDetails?.instructorDetails?.name" >{{courseDetails?.instructorDetails?.name}}</span>
                </div>
            </div>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-details-area pb-100">
    <div >
        <!-- <img *ngIf="courseDetails" src="assets/img/courses/course-details.jpg" alt="image"> -->
        <img *ngIf="courseDetails && courseDetails.bgPhoto" style="aspect-ratio: 96 / 25;" [src]="courseDetails.bgPhoto" alt="image">
    </div>
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="courses-details-desc">
                    <ngx-tabset>
                        <ngx-tab tabTitle="Overview">
                            <div class="courses-overview">
                                <h3>Course Description</h3>
                                <div style="text-align: justify;" *ngIf="courseDetails && courseDetails.description">
                                        {{courseDetails.description}}
                                </div>

                            </div>
                        </ngx-tab>
                        <ngx-tab tabTitle="Curriculum">
                            <div *ngIf="courseDetails && courseDetails.videoModules" class="courses-curriculum">
                                <div *ngFor="let m of courseDetails.videoModules;let mindx=index;" class="moduleListing">
                                    <h3>{{m.moduleTitle}}</h3>
                                <ul>
                                    <li *ngFor="let v of m.videoList;" ><a  class="popup-youtube d-flex justify-content-between align-items-center">
                                        <span class="courses-name">{{v.videoTitle}}</span>
                                        <div class="courses-meta">
                                            <!-- <span class="questions">5 questions</span> -->
                                            <!-- <span class="duration">01 Hour</span> -->
                                            <!-- <span class="status">Preview</span> -->
                                        </div>
                                    </a></li>
                                </ul>
                                </div>
                                
                            </div>
                        </ngx-tab>
                        <ngx-tab tabTitle="Instructor">
                            <div class="courses-instructor">
                                <div class="single-advisor-box">
                                    <div class="row align-items-center">
                                        <div class="col-lg-4 col-md-4">
                                            <div *ngIf="courseDetails && courseDetails.instructorDetails" class="advisor-image">
                                                <img [src]="courseDetails.instructorDetails.trainerImg" alt="image">
                                            </div>
                                        </div>
                                        <div class="col-lg-8 col-md-8">
                                            <div *ngIf="courseDetails && courseDetails.instructorDetails" class="advisor-content">
                                                <h3> {{courseDetails.instructorDetails.name}}</h3>
                                                <span   class="sub-title">{{courseDetails.instructorDetails.expertise}}</span>
                                                <p>{{courseDetails.instructorDetails.description}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ngx-tab>

                    </ngx-tabset>
                </div>
            </div>
            <div class="col-lg-4 col-md-12">
                <div class="courses-details-info">
                    <div class="image">
                        <lightgallery *ngIf="courseDetails" [settings]="settings" [onBeforeSlide]="onBeforeSlide">
                            <a
                                data-lg-size="1280-720"
                                [attr.data-src]="courseDetails.coursePreviewVideo"
                               
                                data-sub-html="<p>Watch Video</p>"
                                class="d-block"
                            >
                                <img *ngIf="courseDetails" [src]="courseDetails.mainPhoto" alt="image">
                                <div class="content">
                                    <i class="flaticon-play"></i>
                                    <span>Course Preview</span>
                                </div>
                            </a>
                        </lightgallery>
                    </div>
                    <ul *ngIf="courseDetails"  class="info">
                        <li class="price">
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-tag"></i> Price</span>
                                <span style="text-decoration: line-through;">₹ {{courseDetails.displayPrice}}</span>
                                ₹ {{courseDetails.actualPrice}}
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-teacher"></i> Instructor</span>
                                {{courseDetails?.instructorDetails?.name}}
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-time"></i> Duration</span>
                                {{courseDetails.courseDurationinHours}} Hours
                            </div>
                        </li>
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-distance-learning"></i>Modules</span>
                                {{courseDetails.noOfModules}}
                            </div>
                        </li>
                        <!-- <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-web"></i> Enrolled</span>
                                {{courseDetails.noOfEnrolledStudents}} students
                            </div>
                        </li> -->
                        <li>
                            <div class="d-flex justify-content-between align-items-center">
                                <span><i class="flaticon-lock"></i> Access</span>
                                {{courseDetails.courseValidityinDays}} days
                            </div>
                        </li>
                    </ul>
                    <div class="btn-box">
                        <a style="color: red;
                        font-weight: bold;text-align: justify;" >Disclaimer of Copyright : This Course's content is subject to copyrights, any wrongful attempts will attract legal proceeding<span></span></a>

                        <a style="cursor:pointer;" (click)="onBuyNow()" class="default-btn"><i class="flaticon-tag"></i>Buy Now<span></span></a>
                        <a style="cursor:pointer;" *ngIf="isLoggedIn" (click)="goToViewCourse()" class="default-btn"><i class="flaticon-lock"></i>View Now<span></span></a>
                
                    </div>
                    <div class="courses-share">
                        <div (click)="shareVideo()" class="share-info">
                            <span>Share This Course <i class="flaticon-share"></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div *ngIf="paymentModalVisble" class="pymtModalOutr">
    <div class="pymtModalinnr">
        <div (click)="paymentModalVisble = false" class="closico">
            X
        </div>
        <div class="pmtModeTxt">
            Select Payment Mode : 
        </div>

        <div style="display: flex;flex-direction: row;">
            <div (click)="setPayMode('online')" [ngStyle]="{'background-color': (selectedPaymentType=='online') ? '#01008b' : '#00638b'}" class="paymode">
                Online Payment
            </div>
            <div (click)="setPayMode('bank')" [ngStyle]="{'background-color': (selectedPaymentType=='bank') ? '#01008b' : '#00638b'}" class="paymode">
                Bank Transfer
            </div>
        </div>
        <div style="padding: 5px;" *ngIf="selectedPaymentType=='online'">
            Click below to proceed with Online Payment
            <br/>

            You are making Payment of amount  <span style="    font-weight: bold;
            font-size: 20px;">: ₹ {{courseDetails.actualPrice}}</span>
            <br/>

            <div (click)="onPaySubmit()"  style="background-color: rgb(4 247 9);
            color: black;
            font-weight: bold;" class="paymode">
                Pay Now
            </div>

        </div>
        <div *ngIf="selectedPaymentType=='bank'">

            <div style="background: #fdf0bc;
            padding: 5px;
            border: dashed 1px red;text-align: justify;">
                For course Activation, after Payment, Submit screenshot of Payment having Unique Trasaction Number (UTR No.),
                along with your <b> registered email and phone number and Course Name  via WhatsApp </b> to : 
                <a style="color: red;
                font-weight: bold;" >+91 9819198758</a>
                
            </div>

            Make Payment of amount  <span style="    font-weight: bold;
            font-size: 20px;">₹ {{courseDetails.actualPrice}}</span>
            <br/>
            via UPI (Scan QR below) or via GPay/PhonePe no. - <span style="font-weight: bold;">9819198758</span> 
            <br/>
            <img style="height: 180px;  display: block;
            margin-left: auto;
            margin-right: auto;" src="assets/img/gyaanQR.jpeg" alt="image">
            <br/>
            Also you can tranfer payment via Bank Transfer, details as Below : 
            <br/>
            <span style="font-weight: bold;">
                Edufinpact Labs Pvt Ltd<br/>
                Ac no - 50200070594469<br/>
                IFSC - HDFC0001576<br/>
                CURRENT ACCOUNT<br/>
                HDFC BANK<br/>
                MULUND WEST
            </span>

        </div>
        
    </div>
</div>

