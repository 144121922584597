import { Component, OnInit } from '@angular/core';
import lgVideo from 'lightgallery/plugins/video';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { ActivatedRoute } from '@angular/router';
import videojs from 'video.js';
import watermark from 'videojs-watermark';
import dwatermark from 'videojs-dynamic-watermark';
import { LoaderService } from 'src/app/core/services/loader.service';



// declare var videojs : any ;



var player

@Component({
  selector: 'app-watch-video',
  templateUrl: './watch-video.component.html',
  styleUrls: ['./watch-video.component.scss']
})
export class WatchVideoComponent implements OnInit {

  courseDetails: any;
  courseID: any;
  selectedVideo: any;

  constructor(public apiHttp: ApiHttpService, private route: ActivatedRoute, public loader: LoaderService) { }

  ngOnInit(): void {
    this.loader.showLoader();
    this.route.queryParams.subscribe((qp) => {
      this.loader.hideLoader();
      this.courseID = this.route.snapshot.params['type'];
      this.getCoursesData(this.courseID);
    });

  }

  ngAfterViewInit() {

  }

  getCoursesData(courseID) {
    this.loader.showLoader();
    this.apiHttp.get('course/getcourse/' + courseID).subscribe(data => {
      this.loader.hideLoader();
      this.courseDetails = data['result']['courseDetails'];
      console.log(this.courseDetails, 'courseDetails');



      //     this.player = videojs(document.getElementById('video_1'), {
      //       sources: {
      //         src: '',
      //         type: "video/mp4"
      //       },
      //       "controls": true, 
      //       "autoplay": false, 
      //       "preload": "auto",
      //       responsive: true
      //  }, function onPlayerReady() {

      // })
      videojs.registerPlugin('videojs-dynamic-watermark', dwatermark);
      player = videojs(document.getElementById('video_1'));




    })
  }

  handleVideoChange(event) {
    console.log(event.video);
    this.selectedVideo = event.video;
    this.loader.showLoader();
    this.apiHttp.post('users/watchVideo/', { videoID: event.video._id, courseID:this.courseID }).subscribe(data => {
      this.loader.hideLoader();
      console.log(data);
      let vidurl: any = data['result']
      if (vidurl && vidurl.length > 0) {
        let wtrmrk = JSON.parse(localStorage.getItem('userDetails'));
        // this.player.src({ type: 'video/mp4', src: vidurl });
        // this.player.play();
        player.src({ type: 'video/mp4', src: vidurl });
        player.play();
        // player.watermark({image:'assets/img/LogoTest.png',position:"top-left"});
   
        let vidwtrmrk = document.getElementById("unique_id")
        if(!vidwtrmrk)
        player.dynamicWatermark({
          elementId: "unique_id",
          watermarkText: `${wtrmrk.fname} ${wtrmrk.lname}:${wtrmrk.mobile}`,
          changeDuration: 34500,
          cssText:
            "display: inline-block; color: #1f1f1f; background-color: transparent; font-size: 1rem; z-index: 9999; position: absolute; @media only screen and (max-width: 992px){font-size: 0.8rem;}",
        })
      }
    })

  }


}