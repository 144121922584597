import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_ENDPOINT } from 'src/config/constants';
import { ApiHttpService } from './api-http.service';
 
const httpOptions = {
headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};
declare var Razorpay: any;
 
@Injectable({
providedIn: 'root'
})
export class OrderService {
 
    constructor(public apiHttp:ApiHttpService) {
 
    }
   
    createOrder(courseID): Observable<any> {
        return this.apiHttp.post('course/createpayorder/'+courseID, {}, httpOptions);
    }
   
    updateOrder(order): Observable<any> {
        return this.apiHttp.post('course/verifypayorder', {
        payment_id: order.payment_id,
        courseID:order.courseID
        }, httpOptions);
    }
}