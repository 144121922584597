<div class="courses-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Go at your own pace</span>
            <h2>The World’s Largest Selection Of Courses</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item without-box-shadow">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="assets/img/courses-small/img1.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$39</span>
                                <h3><a routerLink="/single-courses-1">Agile Crash Course: Agile Project Management</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                                    <li><i class='flaticon-people'></i> 145 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item without-box-shadow">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="assets/img/courses-small/img2.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$99</span>
                                <h3><a routerLink="/single-courses-1">Vue JS 2 - The Complete Guide (incl. Vue Router & Vuex)</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 14 Lessons</li>
                                    <li><i class='flaticon-people'></i> 100 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item without-box-shadow">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="assets/img/courses-small/img3.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$49</span>
                                <h3><a routerLink="/single-courses-1">The Python Bible™ | Everything You Need to Program in Python</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 11 Lessons</li>
                                    <li><i class='flaticon-people'></i> 104 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item without-box-shadow">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="assets/img/courses-small/img4.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$79</span>
                                <h3><a routerLink="/single-courses-1">Mathematical Foundation For Machine Learning and AI</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 14 Lessons</li>
                                    <li><i class='flaticon-people'></i> 100 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item without-box-shadow">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="assets/img/courses-small/img5.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>
                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$59</span>
                                <h3><a routerLink="/single-courses-1">The Ultimate Drawing Course - Beginner to Advanced</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 09 Lessons</li>
                                    <li><i class='flaticon-people'></i> 150 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="single-courses-item without-box-shadow">
                    <div class="row align-items-center">
                        <div class="col-lg-4 col-md-4">
                            <div class="courses-image">
                                <img src="assets/img/courses-small/img6.jpg" alt="image">
                                <a routerLink="/single-courses-1" class="link-btn"></a>
                            </div>
                        </div>

                        <div class="col-lg-8 col-md-8">
                            <div class="courses-content">
                                <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                <span class="price">$89</span>
                                <h3><a routerLink="/single-courses-1">PyTorch: Deep Learning and Artificial Intelligence</a></h3>
                                <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                    <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                    <li><i class='flaticon-people'></i> 200 Students</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="courses-info">
                    <a routerLink="/courses-1" class="default-btn"><i class="flaticon-user"></i>View All Courses<span></span></a>
                    <p>Get into details now?​ <a routerLink="/courses-1">PM Master’s Program</a></p>
                </div>
            </div>
        </div>
    </div>
</div>