<app-kindergarten-banner></app-kindergarten-banner>

<app-kindergarten-about></app-kindergarten-about>

<app-kindergarten-services></app-kindergarten-services>

<app-kindergarten-courses></app-kindergarten-courses>

<app-kindergarten-feedback></app-kindergarten-feedback>

<app-selected-ages></app-selected-ages>

<app-kindergarten-events></app-kindergarten-events>

<app-kindergarten-blog></app-kindergarten-blog>

<app-subscribe-style-four></app-subscribe-style-four>