<div class="features-area pt-100 pb-70 bg-fff8f8">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">EDUCATION FOR EVERYONE</span>
            <h2>Online Coaching Lessons For Remote Learning​</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="features-box">
                    <div class="icon">
                        <i class="flaticon-brain-process"></i>
                    </div>
                    <h3>Learn the Latest Skills</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                    <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>
                    <div class="back-icon">
                        <i class="flaticon-brain-process"></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="features-box">
                    <div class="icon">
                        <i class="flaticon-shield-1"></i>
                    </div>
                    <h3>Learn from Industry Experts</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                    <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>
                    <div class="back-icon">
                        <i class="flaticon-shield-1"></i>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-sm-6 col-md-6">
                <div class="features-box">
                    <div class="icon">
                        <i class="flaticon-world"></i>
                    </div>
                    <h3>Learn Anywhere in the World</h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor </p>
                    <a routerLink="/profile-authentication" class="link-btn">Start Now!</a>
                    <div class="back-icon">
                        <i class="flaticon-world"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>