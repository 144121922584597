import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kitchen-coaching-about',
  templateUrl: './kitchen-coaching-about.component.html',
  styleUrls: ['./kitchen-coaching-about.component.scss']
})
export class KitchenCoachingAboutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
