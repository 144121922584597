import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-kitchen-coaching-banner',
  templateUrl: './kitchen-coaching-banner.component.html',
  styleUrls: ['./kitchen-coaching-banner.component.scss']
})
export class KitchenCoachingBannerComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
