<div class="feedback-area bg-f9f9f9 pt-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-4 col-md-12">
                <div class="coaching-feedback-image">
                    <img src="assets/img/business-coach/business-woman.jpg" alt="image">
                </div>
            </div>
            <div class="col-lg-8 col-md-12">
                <div class="coaching-feedback-slides">
                    <owl-carousel-o [options]="coachingFeedbackSlides">
                        <ng-template carouselSlide>
                            <div class="single-coaching-feedback-box">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis. Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                <div class="client-info d-flex align-items-center">
                                    <img src="assets/img/user/user1.jpg" alt="user">
                                    <div class="title">
                                        <h3>John Smith</h3>
                                        <span>Python Developer</span>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="single-coaching-feedback-box">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis. Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                <div class="client-info d-flex align-items-center">
                                    <img src="assets/img/user/user2.jpg" alt="user">
                                    <div class="title">
                                        <h3>John Smith</h3>
                                        <span>Python Developer</span>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                        <ng-template carouselSlide>
                            <div class="single-coaching-feedback-box">
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices. Risus commodo viverra maecenas accumsan lacus vel facilisis. Consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                                <div class="client-info d-flex align-items-center">
                                    <img src="assets/img/user/user3.jpg" alt="user">
                                    <div class="title">
                                        <h3>John Smith</h3>
                                        <span>Python Developer</span>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </owl-carousel-o>
                </div>
            </div>
        </div>
    </div>
</div>