<div class="view-all-courses-area-two ptb-70 bg-fef8ef">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="view-all-courses-content">
                    <span class="sub-title">Distance learning</span>
                    <h2>Get ahead with Learning Paths. Stay Sharp.</h2>
                    <p>With The Open University you can study whenever and wherever you choose. We have students in over 128 countries, and a global reputation as a pioneer in the field of flexible learning. Our flexible teaching also means, if you travel often or need to relocate, you can continue to study wherever you go.</p>
                    <a routerLink="/courses-2" class="default-btn"><i class="flaticon-agenda"></i>View All Courses<span></span></a>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="view-all-courses-image">
                    <img src="assets/img/woman-with-book.png" alt="image">
                    <div class="shape11"><img src="assets/img/shape10.png" alt="image"></div>
                    <div class="shape12"><img src="assets/img/shape11.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>