import { Component, OnInit } from '@angular/core';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'app-si-courses',
  templateUrl: './si-courses.component.html',
  styleUrls: ['./si-courses.component.scss']
})
export class SiCoursesComponent implements OnInit {
  courseList:any;
  constructor(public apiHttp:ApiHttpService,public loader:LoaderService) { }

  ngOnInit(): void {
    this.loader.showLoader();
    this.apiHttp.get('course/allcourses').subscribe(data=>{
      this.loader.hideLoader();
      this.courseList = data['result'];
      console.log(this.courseList);
      
    })
  }

}
