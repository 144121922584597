import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-courses-types',
  templateUrl: './courses-types.component.html',
  styleUrls: ['./courses-types.component.scss']
})
export class CoursesTypesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
