<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Profile</li>
            </ul>
            <h2 class="hding">My Account</h2>
        </div>
    </div>

</div>

<div class="profile-area pb-70" style="margin-top: -50px;">
    <div class="container">
        <div class="profile-courses-quizzes">
            <ngx-tabset>
                <ngx-tab tabTitle="Active Courses">
              
                    
                                  <div>
                                    My courses
                                  </div>

                                  <div *ngIf="subbedCourses && subbedCourses.length == 0" style="color: blue;font-size: 12px;">
                                    No Courses Subscribed yet
                                  </div>

                                  <div class="courses-area pb-70">
    <div class="container">
        <div class="odemy-grid-sorting row align-items-center">
            <div class="col-lg-6 col-md-6 result-count">
                <!-- <p>We found <span class="count">12</span> courses available for you</p> -->
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let course of subbedCourses">
                <div class="single-courses-item-box" >
                    <div class="courses-image">
                        <a style="cursor: pointer" (click)="goToViewCourse(course._id)"  class="d-block image">
                            <img [src]="course.mainPhoto" class="mnimg" alt="image">
                            <!-- <img [src]="course.mainPhoto" alt="image"> -->
                        </a>
             
                    </div>
                    <div class="courses-content">
                        <h3><a style="cursor: pointer" (click)="goToViewCourse(course._id)" >{{course.name}}</a></h3>
                        <div class="rating">
                           Instructor : {{course?.instructorDetails?.name}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

                       

               
                </ngx-tab>
                <ngx-tab tabTitle="Exam Quiz">
                  
                       
                            <div class="table-responsive">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Course</th>
                                            <th>Action</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="myData">
                                        <tr *ngFor="let c of myData.hasAccesstoCourse">
                                            <td *ngIf="subbedCourses && subbedCourses.length > 0"><a>{{fetchCourseName(c.courseId)}}</a></td>
                                            <td style="color:blue;cursor:pointer">
                                                <button class="subBtn" (click)="qAction(c)">
                                                    {{(c.certificateDetail == null) ? 'Start Quiz' : 'Completed (Download Certificate)' }}
                                                </button>
                                            </td>
                                            <td *ngIf="c.quizAttempts < 4 && c.certificateDetail != null" (click)="reAttemptQuiz(c)"
                                                style="color:blue;cursor:pointer"> ReAttempt Quiz</td>
                                            <td *ngIf="(c.quizAttempts >= 3 )" style="color:blue;cursor:not-allowed"> ReAttempts Exausted</td>
                                            <td *ngIf="(c.quizAttempts < 4  && c.certificateDetail == null)" style="color:blue;cursor:not-allowed"> Attempts : {{c.quizAttempts}}/4</td>
                                        </tr>
                                       
                                    </tbody>
                                </table>
                            </div>
                       
               
                </ngx-tab>
            </ngx-tabset>
        </div>
    </div>
</div>