<div style="margin-bottom: 40px;" appNoRightClick>

<div class="outrr">

    <div class="plyrdv" >
    <video oncontextmenu="return false;"  id="video_1"
    class="video-js vjs-default-skin vjs-big-play-centered vjs-fluid"
    controls  type="video/mp4"  width="640" height="264">
 </video>

 <div class="vidttl">

    <div class="col-lg-8 col-md-12">
        <div class="courses-details-desc">
            <ngx-tabset>
                <ngx-tab tabTitle="Overview">
                    <div class="courses-overview">
                      
                        <div *ngIf="courseDetails && selectedVideo">
                              
                                {{selectedVideo.videoTitle}}
                        </div>

                        <div class="vidDsc" *ngIf="selectedVideo">
                            {{selectedVideo.videoDescription}}
                         </div>

                    </div>
                </ngx-tab>

                <ngx-tab tabTitle="Instructor">
                    <div class="courses-instructor">
                        <div class="single-advisor-box">
                            <div class="row">
                                <div class="col-lg-4 col-md-4">
                                    <div class="advisor-image">
                                        <img [src]="courseDetails.instructorDetails.trainerImg" alt="image">
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-8">
                                    <div class="advisor-content">
                                        <h3 *ngIf="courseDetails && courseDetails.instructorName"> {{courseDetails.instructorDetails.name}}</h3>
                                        <span class="sub-title">{{courseDetails.instructorDetails.expertise}}</span>
                                        <p>{{courseDetails.instructorDetails.description}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>

            </ngx-tabset>
        </div>
    </div>
    
     
</div>


</div>

    <div class="ccdv" >
        <app-video-module-listing (change)="handleVideoChange($event)" *ngIf="courseDetails" [courseDetails]="courseDetails"></app-video-module-listing>
    </div>

</div>





</div>