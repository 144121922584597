import { Injectable } from '@angular/core'; 
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable() 
export class LoaderService { 
    isLoading = false;
constructor(private spinner: NgxSpinnerService ) {
   
 } 

 showLoader()
 {
   this.spinner.show();
   this.isLoading = true;
 }

 hideLoader()
 {
   this.spinner.hide();
   this.isLoading = false;
 }

 getLoader()
 {
    return this.isLoading;
 }

}