<div class="pricing-area bg-f9f9f9 pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Pricing</span>
            <h2 class="playfair-display-font">Our Flexible Pricing Plan</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <img src="assets/img/yoga-pricing-img1.png" alt="image">
                        <h3>200 Hour TTC</h3>
                    </div>
                    <div class="pricing-features">
                        <ul>
                            <li>Examine traditional yoga asanas</li>
                            <li>Learn meditation and philosophy</li>
                            <li>Connect energetics and anatomy</li>
                            <li>Practice teaching for confidence</li>
                            <li>Become a Yoga Alliance RYT200</li>
                        </ul>
                    </div>
                    <div class="price">
                        $499.99
                        <span>Yoga Alliance Certified</span>
                    </div>
                    <a routerLink="/" class="default-btn">Book Now<span></span></a>
                    <div class="pricing-shape1"><img src="assets/img/pricing-shape1.png" alt="image"></div>
                    <div class="pricing-shape2"><img src="assets/img/pricing-shape2.png" alt="image"></div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <img src="assets/img/yoga-pricing-img2.png" alt="image">
                        <h3>200 Hour TTC</h3>
                    </div>
                    <div class="pricing-features">
                        <ul>
                            <li>Fine tune yoga alignment and form</li>
                            <li>Explore the fine art of sequencing</li>
                            <li>Delve deeper into ancient scripture</li>
                            <li>Study therapeutic based anatomy</li>
                            <li>Become a Yoga Alliance RYT300</li>
                        </ul>
                    </div>
                    <div class="price">
                        $599.99
                        <span>Yoga Alliance Certified</span>
                    </div>
                    <a routerLink="/" class="default-btn">Book Now<span></span></a>
                    <div class="pricing-shape1"><img src="assets/img/pricing-shape1.png" alt="image"></div>
                    <div class="pricing-shape2"><img src="assets/img/pricing-shape2.png" alt="image"></div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <img src="assets/img/yoga-pricing-img3.png" alt="image">
                        <h3>500 Hour TTC</h3>
                    </div>
                    <div class="pricing-features">
                        <ul>
                            <li>Get the highest level certification</li>
                            <li>Become a respected yoga master</li>
                            <li>Work at luxury resorts and retreats</li>
                            <li>Lead your own teacher trainings</li>
                            <li>Become a Yoga Alliance RYT500</li>
                        </ul>
                    </div>
                    <div class="price">
                        $699.99
                        <span>Yoga Alliance Certified</span>
                    </div>
                    <a routerLink="/" class="default-btn">Book Now<span></span></a>
                    <div class="pricing-shape1"><img src="assets/img/pricing-shape1.png" alt="image"></div>
                    <div class="pricing-shape2"><img src="assets/img/pricing-shape2.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
</div>