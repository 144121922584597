import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-learning-view-courses',
  templateUrl: './learning-view-courses.component.html',
  styleUrls: ['./learning-view-courses.component.scss']
})
export class LearningViewCoursesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
