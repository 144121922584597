<div class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-image text-center">
                    <img src="assets/img/about-img11.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">About Us</span>
                    <h2 class="playfair-display-font">Feel Like You Are Attending Your Classes Physically!</h2>
                    <p>XYZ training programs can bring you a super exciting experience of learning through online! You never face any negative experience while enjoying your classes virtually by sitting in your comfort zone. Our flexible learning initiatives will help you to better and quicker than the traditional ways of learning skills.</p>
                    <a routerLink="/profile-authentication" class="default-btn mt-2"><i class="flaticon-user"></i>Join For Free<span></span></a>
                </div>
            </div>
        </div>
    </div>
</div>