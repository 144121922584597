<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Become A Teacher</li>
            </ul>
            <h2>Become A Teacher</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<app-apply-instructor></app-apply-instructor>

<app-teacher-register></app-teacher-register>