import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-health-feedback',
  templateUrl: './health-feedback.component.html',
  styleUrls: ['./health-feedback.component.scss']
})
export class HealthFeedbackComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
