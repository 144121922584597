<div class="kitchen-courses-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Go At Your Own Pace</span>
            <h2 class="font-weight-black">Top Selling Courses</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-xl-4 col-lg-6 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img src="assets/img/courses/kitchen-1.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">$39</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user/user1.jpg" class="rounded-circle" alt="image">
                            <span>Alex Morgan</span>
                        </div>
                        <h3 class="font-weight-black"><a routerLink="/single-courses-1">Entrepreneurship in Food and Beverage</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li>
                                <i class='flaticon-agenda'></i> 15 Lessons
                            </li>
                            <li>
                                <i class='flaticon-people'></i> 145 Students
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img src="assets/img/courses/kitchen-2.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">$49</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user/user2.jpg" class="rounded-circle" alt="image">
                            <span>Sarah Taylor</span>
                        </div>
                        <h3 class="font-weight-black"><a routerLink="/single-courses-1">Become a Successful Baker with BBC Good Food</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li>
                                <i class='flaticon-agenda'></i> 20 Lessons
                            </li>
                            <li>
                                <i class='flaticon-people'></i> 100 Students
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img src="assets/img/courses/kitchen-3.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">$59</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user/user3.jpg" class="rounded-circle" alt="image">
                            <span>David Warner</span>
                        </div>
                        <h3 class="font-weight-black"><a routerLink="/single-courses-1">Exploring English: Food and Culture</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li>
                                <i class='flaticon-agenda'></i> 20 Lessons
                            </li>
                            <li>
                                <i class='flaticon-people'></i> 150 Students
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img src="assets/img/courses/kitchen-4.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">$69</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user/user4.jpg" class="rounded-circle" alt="image">
                            <span>James Anderson</span>
                        </div>
                        <h3 class="font-weight-black"><a routerLink="/single-courses-1">Learn How to Bake with BBC Good Food</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li>
                                <i class='flaticon-agenda'></i> 20 Lessons
                            </li>
                            <li>
                                <i class='flaticon-people'></i> 150 Students
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img src="assets/img/courses/kitchen-5.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">$79</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user/user5.jpg" class="rounded-circle" alt="image">
                            <span>Alex Morgan</span>
                        </div>
                        <h3 class="font-weight-black"><a routerLink="/single-courses-1">Nutrition: Healthy Cooking, Healthy Living</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li>
                                <i class='flaticon-agenda'></i> 20 Lessons
                            </li>
                            <li>
                                <i class='flaticon-people'></i> 150 Students
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-xl-4 col-lg-6 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a routerLink="/single-courses-1" class="d-block image">
                            <img src="assets/img/courses/kitchen-6.jpg" alt="image">
                        </a>
                        <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                        <div class="price shadow">$79</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img src="assets/img/user/user6.jpg" class="rounded-circle" alt="image">
                            <span>David Warner</span>
                        </div>
                        <h3 class="font-weight-black"><a routerLink="/single-courses-1">Introduction to Sustainable Practices in Food Service</a></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et.</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li>
                                <i class='flaticon-agenda'></i> 20 Lessons
                            </li>
                            <li>
                                <i class='flaticon-people'></i> 150 Students
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="col-xl-12 col-lg-12 col-md-12">
                <div class="courses-info">
                    <p>Get the most dedicated consultation for your life-changing course. Earn a certification for your effort and passion <a routerLink="/profile-authentication">Join Fre Now!</a>.</p>
                </div>
            </div>
        </div>
    </div>
</div>