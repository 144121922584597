<div class="courses-area bg-f5f7fa pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span class="sub-title">Go At Your Own Pace</span>
            <h2>The World’s Largest Selection Of Courses</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="courses-tabs">
            <ngx-tabset>
                <ngx-tab tabTitle="All">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item-box">
                                <div class="courses-image">
                                    <a routerLink="/single-courses-2" class="d-block image">
                                        <img src="assets/img/courses/img12.jpg" alt="image">
                                    </a>
                                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                    <div class="price shadow">$39</div>
                                </div>
                                <div class="courses-content">
                                    <h3><a routerLink="/single-courses-2">Agile Crash Course: Agile Project Management</a></h3>
                                    <div class="rating">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <div class="rating-total">
                                            5.0 (1 rating)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item-box">
                                <div class="courses-image">
                                    <a routerLink="/single-courses-2" class="d-block image">
                                        <img src="assets/img/courses/img13.jpg" alt="image">
                                    </a>
                                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                    <div class="price shadow">$59</div>
                                </div>
                                <div class="courses-content">
                                    <h3><a routerLink="/single-courses-2">Vue JS 2 - The Complete Guide (incl. Vue Router & Vuex)</a></h3>
                                    <div class="rating">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <div class="rating-total">
                                            5.0 (2 rating)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item-box">
                                <div class="courses-image">
                                    <a routerLink="/single-courses-2" class="d-block image">
                                        <img src="assets/img/courses/img14.jpg" alt="image">
                                    </a>
                                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                    <div class="price shadow">$69</div>
                                </div>
                                <div class="courses-content">
                                    <h3><a routerLink="/single-courses-2">The Python Bible™ | Everything You Need to Program in Python</a></h3>
                                    <div class="rating">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <div class="rating-total">
                                            5.0 (3 rating)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item-box">
                                <div class="courses-image">
                                    <a routerLink="/single-courses-2" class="d-block image">
                                        <img src="assets/img/courses/img15.jpg" alt="image">
                                    </a>
                                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                    <div class="price shadow">$79</div>
                                </div>
                                <div class="courses-content">
                                    <h3><a routerLink="/single-courses-2">Mathematical Foundation For Machine Learning and AI</a></h3>
                                    <div class="rating">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <div class="rating-total">
                                            5.0 (4 rating)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item-box">
                                <div class="courses-image">
                                    <a routerLink="/single-courses-2" class="d-block image">
                                        <img src="assets/img/courses/img16.jpg" alt="image">
                                    </a>
                                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                    <div class="price shadow">$89</div>
                                </div>
                                <div class="courses-content">
                                    <h3><a routerLink="/single-courses-2">The Ultimate Drawing Course - Beginner to Advanced</a></h3>
                                    <div class="rating">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <div class="rating-total">
                                            5.0 (5 rating)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item-box">
                                <div class="courses-image">
                                    <a routerLink="/single-courses-2" class="d-block image">
                                        <img src="assets/img/courses/img17.jpg" alt="image">
                                    </a>
                                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                    <div class="price shadow">$99</div>
                                </div>
                                <div class="courses-content">
                                    <h3><a routerLink="/single-courses-2">PyTorch: Deep Learning and Artificial Intelligence</a></h3>
                                    <div class="rating">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <div class="rating-total">
                                            5.0 (6 rating)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Development">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item-box">
                                <div class="courses-image">
                                    <a routerLink="/single-courses-2" class="d-block image">
                                        <img src="assets/img/courses/img12.jpg" alt="image">
                                    </a>
                                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                    <div class="price shadow">$39</div>
                                </div>
                                <div class="courses-content">
                                    <h3><a routerLink="/single-courses-2">Agile Crash Course: Agile Project Management</a></h3>
                                    <div class="rating">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <div class="rating-total">
                                            5.0 (1 rating)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item-box">
                                <div class="courses-image">
                                    <a routerLink="/single-courses-2" class="d-block image">
                                        <img src="assets/img/courses/img14.jpg" alt="image">
                                    </a>
                                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                    <div class="price shadow">$69</div>
                                </div>
                                <div class="courses-content">
                                    <h3><a routerLink="/single-courses-2">The Python Bible™ | Everything You Need to Program in Python</a></h3>
                                    <div class="rating">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <div class="rating-total">
                                            5.0 (3 rating)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Business">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item-box">
                                <div class="courses-image">
                                    <a routerLink="/single-courses-2" class="d-block image">
                                        <img src="assets/img/courses/img12.jpg" alt="image">
                                    </a>
                                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                    <div class="price shadow">$39</div>
                                </div>
                                <div class="courses-content">
                                    <h3><a routerLink="/single-courses-2">Agile Crash Course: Agile Project Management</a></h3>
                                    <div class="rating">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <div class="rating-total">
                                            5.0 (1 rating)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item-box">
                                <div class="courses-image">
                                    <a routerLink="/single-courses-2" class="d-block image">
                                        <img src="assets/img/courses/img13.jpg" alt="image">
                                    </a>
                                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                    <div class="price shadow">$59</div>
                                </div>
                                <div class="courses-content">
                                    <h3><a routerLink="/single-courses-2">Vue JS 2 - The Complete Guide (incl. Vue Router & Vuex)</a></h3>
                                    <div class="rating">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <div class="rating-total">
                                            5.0 (2 rating)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="IT & Software">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item-box">
                                <div class="courses-image">
                                    <a routerLink="/single-courses-2" class="d-block image">
                                        <img src="assets/img/courses/img14.jpg" alt="image">
                                    </a>
                                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                    <div class="price shadow">$69</div>
                                </div>
                                <div class="courses-content">
                                    <h3><a routerLink="/single-courses-2">The Python Bible™ | Everything You Need to Program in Python</a></h3>
                                    <div class="rating">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <div class="rating-total">
                                            5.0 (3 rating)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item-box">
                                <div class="courses-image">
                                    <a routerLink="/single-courses-2" class="d-block image">
                                        <img src="assets/img/courses/img17.jpg" alt="image">
                                    </a>
                                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                    <div class="price shadow">$99</div>
                                </div>
                                <div class="courses-content">
                                    <h3><a routerLink="/single-courses-2">PyTorch: Deep Learning and Artificial Intelligence</a></h3>
                                    <div class="rating">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <div class="rating-total">
                                            5.0 (6 rating)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Design">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item-box">
                                <div class="courses-image">
                                    <a routerLink="/single-courses-2" class="d-block image">
                                        <img src="assets/img/courses/img13.jpg" alt="image">
                                    </a>
                                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                    <div class="price shadow">$59</div>
                                </div>
                                <div class="courses-content">
                                    <h3><a routerLink="/single-courses-2">Vue JS 2 - The Complete Guide (incl. Vue Router & Vuex)</a></h3>
                                    <div class="rating">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <div class="rating-total">
                                            5.0 (2 rating)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item-box">
                                <div class="courses-image">
                                    <a routerLink="/single-courses-2" class="d-block image">
                                        <img src="assets/img/courses/img15.jpg" alt="image">
                                    </a>
                                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                    <div class="price shadow">$79</div>
                                </div>
                                <div class="courses-content">
                                    <h3><a routerLink="/single-courses-2">Mathematical Foundation For Machine Learning and AI</a></h3>
                                    <div class="rating">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <div class="rating-total">
                                            5.0 (4 rating)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Animation">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item-box">
                                <div class="courses-image">
                                    <a routerLink="/single-courses-2" class="d-block image">
                                        <img src="assets/img/courses/img15.jpg" alt="image">
                                    </a>
                                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                    <div class="price shadow">$79</div>
                                </div>
                                <div class="courses-content">
                                    <h3><a routerLink="/single-courses-2">Mathematical Foundation For Machine Learning and AI</a></h3>
                                    <div class="rating">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <div class="rating-total">
                                            5.0 (4 rating)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item-box">
                                <div class="courses-image">
                                    <a routerLink="/single-courses-2" class="d-block image">
                                        <img src="assets/img/courses/img16.jpg" alt="image">
                                    </a>
                                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                    <div class="price shadow">$89</div>
                                </div>
                                <div class="courses-content">
                                    <h3><a routerLink="/single-courses-2">The Ultimate Drawing Course - Beginner to Advanced</a></h3>
                                    <div class="rating">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <div class="rating-total">
                                            5.0 (5 rating)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
                <ngx-tab tabTitle="Illustration">
                    <div class="row justify-content-center">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item-box">
                                <div class="courses-image">
                                    <a routerLink="/single-courses-2" class="d-block image">
                                        <img src="assets/img/courses/img15.jpg" alt="image">
                                    </a>
                                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                    <div class="price shadow">$79</div>
                                </div>
                                <div class="courses-content">
                                    <h3><a routerLink="/single-courses-2">Mathematical Foundation For Machine Learning and AI</a></h3>
                                    <div class="rating">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <div class="rating-total">
                                            5.0 (4 rating)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item-box">
                                <div class="courses-image">
                                    <a routerLink="/single-courses-2" class="d-block image">
                                        <img src="assets/img/courses/img16.jpg" alt="image">
                                    </a>
                                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                    <div class="price shadow">$89</div>
                                </div>
                                <div class="courses-content">
                                    <h3><a routerLink="/single-courses-2">The Ultimate Drawing Course - Beginner to Advanced</a></h3>
                                    <div class="rating">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <div class="rating-total">
                                            5.0 (5 rating)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-courses-item-box">
                                <div class="courses-image">
                                    <a routerLink="/single-courses-2" class="d-block image">
                                        <img src="assets/img/courses/img17.jpg" alt="image">
                                    </a>
                                    <a routerLink="/" class="fav"><i class="flaticon-heart"></i></a>
                                    <div class="price shadow">$99</div>
                                </div>
                                <div class="courses-content">
                                    <h3><a routerLink="/single-courses-2">PyTorch: Deep Learning and Artificial Intelligence</a></h3>
                                    <div class="rating">
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <i class='bx bxs-star'></i>
                                        <div class="rating-total">
                                            5.0 (6 rating)
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>
            </ngx-tabset>
        </div>
    </div>
    <div class="shape16"><img src="assets/img/shape15.png" alt="image"></div>
</div>