<div class="funfacts-area bg-black pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-funfacts-item with-box-shadow">
                    <h3><span [countUp]="1926">00</span></h3>
                    <p>Finished Sessions</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-funfacts-item with-box-shadow">
                    <h3><span [countUp]="3279">00</span></h3>
                    <p>Enrolled Learners</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-funfacts-item with-box-shadow">
                    <h3><span [countUp]="250">00</span></h3>
                    <p>Online Instructors</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-funfacts-item with-box-shadow">
                    <h3><span [countUp]="100">00</span>%</h3>
                    <p>Satisfaction Rate</p>
                </div>
            </div>
        </div>
    </div>
</div>