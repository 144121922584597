<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Welcome</li>
            </ul>
            <h2>Welcome</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="profile-authentication-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="login-form">
                    <ng-container *ngIf="showLogin">
                        <h2>Login</h2>
                        <div>
                            <div class="form-group">
                                <label>Email</label>
                                <input type="text" [(ngModel)]="loginDetails.uname" class="form-control" placeholder="Email">
                            </div>
                            <div class="form-group">
                                <label>Password</label>
                                <input type="password" [(ngModel)]="loginDetails.pass" class="form-control" placeholder="Password">
                            </div>
                            <div class="row align-items-center">
                                <div class="col-lg-6 col-md-6 col-sm-6 remember-me-wrap">
                                    <p>
                                        <input type="checkbox" id="test2">
                                        <label for="test2">Remember me</label>
                                    </p>
                                </div>
                                <div class="col-lg-6 col-md-6 col-sm-6 lost-your-password-wrap">
                                    <a (click)="showLogin=!showLogin" class="lost-your-password">Lost your password?</a>
                                </div>
                            </div>
                            <br/>
                            <button (click)="attemptLogin()">Log In</button>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="!showLogin">
                        <h3 (click)="showLogin=!showLogin" style="text-decoration: underline;cursor: pointer;">Back to Login</h3>
                        <div>
                            <div class="form-group">
                                <label>Email</label>
                                <input type="text" [(ngModel)]="loginDetails.uname" class="form-control" placeholder="Email">
                            </div>
                            <br/>
                            <div *ngIf="fpSubmitted" style="color: green;" class="form-group">
                                We have recorded your Request, we will send pass recovery intructions via email if found in our records.
                            </div>
                            <br/>
                            <button [disabled]="fpSubmitted" (click)="forgotPass()">Forgot Password</button>
                        </div>
                    </ng-container>
                    
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="register-form">
                    <h2>Register</h2>
                    <div>
                        <div style="color: red;
                        font-weight: bold;">
                            Note: Please fill in the Name correctly,SAME name will appear on the certificate
                        </div>
                        <br/>
                        <div class="form-group">
                            <label>First Name</label>
                            <input [(ngModel)]="signUpDetails.fname" type="text" class="form-control" placeholder="First Name">
                        </div>

                        <div class="form-group">
                            <label>Last Name</label>
                            <input [(ngModel)]="signUpDetails.lname" type="text" class="form-control" placeholder="Last Name">
                        </div>

                        <div class="form-group">
                            <label>Mobile Number (Do not enter Country Code)</label>
                            <input [(ngModel)]="signUpDetails.mobile" type="tel" class="form-control" placeholder="Mobile Number">
                        </div>

                        <div class="form-group">
                            <label>Email</label>
                            <input [(ngModel)]="signUpDetails.email" type="email" class="form-control" placeholder="Email">
                        </div>
                        <div class="form-group">
                            <label>Password</label>
                            <input [(ngModel)]="signUpDetails.password" type="password" class="form-control" placeholder="Password">
                        </div>
                        <p class="description">The password should be at least eight characters long. To make it stronger, use upper and lower case letters, numbers, and symbols like ! " ? $ % ^ & )</p>
                        <button (click)="attemptSignUp()" >Register</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>