import { Component, OnInit, HostListener } from '@angular/core';
import lgVideo from 'lightgallery/plugins/video';
import { BeforeSlideDetail } from 'lightgallery/lg-events';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { OrderService } from 'src/app/core/services/order-service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { LoaderService } from 'src/app/core/services/loader.service';

declare var Razorpay: any;

@Component({
	selector: 'app-courses-details-style-one',
	templateUrl: './courses-details-style-one.component.html',
	styleUrls: ['./courses-details-style-one.component.scss']
})
export class CoursesDetailsStyleOneComponent implements OnInit {

    courseDetails:any;
    public pageType = '';
    paymentId: string;
    error: string;
    form: any = {}; 
    isLoggedIn = false;
    selectedPaymentType = 'online';
    paymentModalVisble = false;

	constructor(
        private route: ActivatedRoute,
        public router: Router,
        private orderService: OrderService,
        public loader:LoaderService,
        public apiHttp:ApiHttpService) {
        
    this.route.queryParams.subscribe((qp) => {
        this.pageType = this.route.snapshot.params['type'];
        if (this.pageType.length > 0) {
          //call api get course data
          this.getCoursesData();
        }
      });

    this.route.queryParams.subscribe(params => {
        let pid = params['pid'];
        if(pid.length > 0)
        {
            this.validatePay(pid)
        }
    })
     }

     options = {
        "key": "",
        "amount": "", 
        "name": "LMS Course",
        "description": "Web Development",
        "order_id":"",
        "handler": function (response){
            var event = new CustomEvent("payment.success", 
                {
                    detail: response,
                    bubbles: true,
                    cancelable: true
                }
            );    
            window.dispatchEvent(event);
        }
        ,
        "prefill": {
        "name": "",
        "email": "",
        "contact": ""
        },
        "notes": {
        "address": ""
        },
        "theme": {
        "color": "#fe4a55"
        }
        };

	ngOnInit(): void {
        if(localStorage.getItem('userToken'))
        {
            this.isLoggedIn = true;
        }
    }

    goToViewCourse()
    {
        //check course access -- else show not subscribed
        this.loader.showLoader();
        this.apiHttp.get('users/myprofile/').subscribe(data=>{
            this.loader.hideLoader();
            let {hasAccesstoCourse} = data['result'];
            console.log(hasAccesstoCourse);
            if(hasAccesstoCourse && hasAccesstoCourse.length > 0)
            {
               let filtred =  (hasAccesstoCourse.filter(c=>c.courseId == this.pageType));
               if(filtred.length > 0)
               {
                    this.router.navigate(['/watch-course/'+this.pageType]);
               }
               else
               {
                Swal.fire({
                    icon: 'error',
                    showConfirmButton: false,
                    position: 'top-end',
                    timer: 2000,
                    title: 'Sorry',
                    text: 'You do not have access to this course, Please purchase the course first.'
                  })
               }
            }
            else
            {
                Swal.fire({
                    icon: 'error',
                    showConfirmButton: false,
                    position: 'top-end',
                    timer: 2000,
                    title: 'Sorry',
                    text: 'You do not have access to this course, Please purchase the course first.'
                  })
            }
        })

    }

    getCoursesData()
    {
        this.loader.showLoader();
        this.apiHttp.get('course/getcourse/'+this.pageType).subscribe(data=>{
            this.loader.hideLoader();
            this.courseDetails = data['result']['courseDetails'];
            console.log(this.courseDetails);
        })
    }   

    settings = {
        counter: false,
        plugins: [lgVideo]
    };
    onBeforeSlide = (detail: BeforeSlideDetail): void => {
        const { index, prevIndex } = detail;
        console.log(index, prevIndex);
    };

    onBuyNow()
    {
        if(this.isLoggedIn)
        {
            this.loader.showLoader();
            this.apiHttp.get('users/myprofile/').subscribe(data=>{
                this.loader.hideLoader();
                let myData = data['result'];
                console.log(myData.hasAccesstoCourse);
                if(myData.hasAccesstoCourse && myData.hasAccesstoCourse.length == 0)
                {
                    this.onPaySubmit();
                    // this.paymentModalVisble = true;
                }
                else
                {
                    let matched = myData.hasAccesstoCourse.filter(c=>c.courseId == this.pageType);
                    if(matched.length > 0)
                    {
                        let currDate = moment();
                        var startDate   = moment(matched[0]['subscriptionStartDate']);
                        var endDate     = moment(matched[0]['subscriptionEndDate']);

                        
                        if(currDate.isBetween(startDate, endDate))
                        {
                            // already access
                            console.log('already access');
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'You already are subscribed to this course',
                                showConfirmButton: false,
                                timer: 2000
                              }).then(()=>{
                                this.goToViewCourse();
                              })
                        }
                        else
                        {
                            this.onPaySubmit();
                            // this.paymentModalVisble = true;
                        }
                        
                    }
                    else
                    {
                        this.onPaySubmit();
                        // this.paymentModalVisble = true;
                    }
                }
                
            })

            
        }
        else
        {
            this.router.navigate(['/profile-authentication/']);
        }
    }

    onPaySubmit()
    {
        this.paymentId = ''; 
        this.error = ''; 
        this.loader.showLoader();
        this.orderService.createOrder(this.pageType).subscribe(
        data => {
            console.log("data",data);
            if(data?.result?.pay_page_url?.length > 0)
            {
                window.open(data?.result?.pay_page_url,"_self")
            }
        }
        ,
        err => {
            this.error = err.error.message;
        }
        );
    }

    validatePay(payment_id){
        this.loader.showLoader();
        this.orderService.updateOrder({payment_id,courseID:this.pageType}).subscribe(
            data => {
                console.log(data);
                
                this.loader.hideLoader();
                // this.paymentId = data.message;
                if(data['result']['status'] == "COMPLETED")
                {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Payment Successful',
                        showConfirmButton: false,
                        timer: 1500
                      }).then(()=>{
                        this.router.navigate([(this.router.url).split("?")[0]])
                      })
                }
                else
                if(data['result']['status'] == "PENDING")
                {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Payment In Progress, Please check back in some time',
                        showConfirmButton: false,
                        timer: 2500
                      }).then(()=>{
                        this.router.navigate([(this.router.url).split("?")[0]])
                      })
                }
                else
                if(data['result']['status'] == "FAILED")
                {
                    Swal.fire({
                        icon: 'error',
                        title: 'Payment Failed',
                        text: 'Something went wrong, Payment issue'
                      }).then(()=>{
                        this.router.navigate([(this.router.url).split("?")[0]])
                      })
                }
                


            }
            ,
            err => {
                this.loader.hideLoader();
                this.error = err.error.message;
                Swal.fire({
                    icon: 'error',
                    title: 'Sorry...',
                    text: 'Something went wrong, Payment issue'
                  })
            }
            );
    }



    shareVideo()
    {
        if (navigator.share) {
            navigator.share({
                title: this.courseDetails.name,
                text: 'Checkout this Course',
                url: window.location.href,
              })
              .then(() => console.log('Successful share'))
              .catch((error) => console.log('Error sharing', error));
          } else {
            navigator.clipboard.writeText(window.location.href);
            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Course URL copied to Clipboard for sharing',
                showConfirmButton: false,
                timer: 2000
              })
          }


    }

    setPayMode(str)
    {
        this.selectedPaymentType = str;
    }


}