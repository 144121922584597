<app-si-banner></app-si-banner>

<app-features></app-features>

<app-si-courses></app-si-courses>



<!-- <app-instructor-quote></app-instructor-quote> -->

<!-- <app-funfacts-style-one></app-funfacts-style-one> -->

<!-- <app-feedback></app-feedback> -->

<!-- <app-si-features></app-si-features> -->

<!-- <app-si-events></app-si-events>

<app-blog></app-blog> -->

<!-- <app-subscribe></app-subscribe> -->