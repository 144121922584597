import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-oa-courses',
    templateUrl: './oa-courses.component.html',
    styleUrls: ['./oa-courses.component.scss']
})
export class OaCoursesComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {}

}