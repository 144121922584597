import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ModalComponent } from './modal/modal.component';
import { Router } from '@angular/router';

@Component({
    selector: 'app-video-module-listing',
    templateUrl: './video-module-listing.html',
    styleUrls: ['./video-module-listing.scss']
})
export class VideoModuleListingComponent implements OnInit {
    isPdfExapanded = false;
    @Input() courseDetails: any;

    @Output() change = new EventEmitter();


    modifiedList:any;
    
    constructor(public router: Router) { }

    ngOnInit(): void {
        console.log("tet",this.getModified());
        this.modifiedList = this.getModified();
        this.selectVideo(0,0);
    }

    ngAfterViewInit(){
        
    }

    gotoQuiz()
    {
        this.router.navigate(['/quiz-page/'+this.courseDetails['_id']]);
        
    }

    getModified()
    {
        return this.courseDetails['videoModules'].map(vm=>{
            vm['isExpanded'] = false;
            vm['videoList'] = vm['videoList'].map(v=>{
                v['isSelected'] = false;
                return v;
            })
            return vm;
        })
    }

    selectVideo(moduleIndx,VideoIndx)
    {
        this.change.next({video:this.modifiedList[moduleIndx]['videoList'][VideoIndx]});
        this.modifiedList = this.modifiedList.map((vm,vmMIndx)=>{
            vm['isExpanded'] = (vmMIndx == moduleIndx);
            vm['videoList'] = vm['videoList'].map((v,vVIndx)=>{
                v['isSelected'] = (VideoIndx == vVIndx && vmMIndx == moduleIndx);
                return v;
            })
            return vm;
        })
    }

    expandModule(moduleIndx)
    {
        if(!this.modifiedList[moduleIndx]['isExpanded'])
        {
            this.modifiedList = this.modifiedList.map((vm,vmMIndx)=>{
                vm['isExpanded'] = (vmMIndx == moduleIndx);
                return vm;
            })
        }
        else
        {
            this.modifiedList[moduleIndx]['isExpanded'] = false;
        }

    }

    openModal() {
       
      }

      openPDF(p){
        window.open(p.link,"_blank")
      }

}