<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Courses</li>
            </ul>
            <h2>Courses</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="courses-area pb-70">
    <div class="container">
        <div class="odemy-grid-sorting row align-items-center">
            <div class="col-lg-6 col-md-6 result-count">
                <!-- <p>We found <span class="count">12</span> courses available for you</p> -->
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6" *ngFor="let course of courseData">
                <div class="single-courses-item-box" >
                    <div class="courses-image">
                        <a [routerLink]="'/course/'+course._id" class="d-block image">
                            <img [src]="course.mainPhoto" class="mnimg" alt="image">
                            <!-- <img [src]="course.mainPhoto" alt="image"> -->
                        </a>
             
                        <div style="    background-color: #FD8503;
                        width: max-content;
                        padding: 2px;
                        padding-inline: 10px;" class="price shadow">₹ {{course.actualPrice}}</div>
                    </div>
                    <div class="courses-content">
                        <h3><a [routerLink]="'/course/'+course._id">{{course.name}}</a></h3>
                        <div class="rating">
                           Instructor : {{course?.instructorDetails?.name}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>