<div class="video-area">
    <div class="container">
        <div class="video-box mt-0">
            <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
                <a
                    data-lg-size="1280-720"
                    data-src="https://www.youtube.com/watch?v=PWvPbGWVRrU"
                    data-sub-html="<p>Watch Video</p>"
                    class="d-block"
                >
                    <div class="image">
                        <img src="assets/img/video-img2.jpg" class="shadow" alt="image">
                    </div>
                    <div class="video-btn popup-youtube">
                        <i class="flaticon-play"></i>
                    </div>
                </a>
            </lightgallery>
        </div>
    </div>
</div>