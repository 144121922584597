import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';

@Injectable()
export class AuthGuardService implements CanActivate {
  constructor(public router: Router) {}
  canActivate(): boolean {
    let tokenDetails = JSON.parse(localStorage.getItem('userToken'));
     
    if (!(tokenDetails && tokenDetails.token && tokenDetails.token.length > 0)) {
      this.router.navigate(['/profile-authentication']);
      return false;
    }
    return true;
  }
}