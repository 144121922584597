<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>FAQ's</li>
            </ul>
            <h2>Frequently Asked Questions</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div class="faq-area ptb-100">
    <div class="container">
        <div class="faq-accordion">
            <accordion>
                <accordion-group heading="What shipping methods are available?">
                    <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                    <p><strong>1.</strong> Sony PlayStation 4 Pro – Best Overall<br>
                        <strong>2.</strong> Xbox One S – Best Gaming Console for Multimedia<br>
                        <strong>3.</strong> Nintendo Switch – Best Hybrid Gaming Console<br>
                        <strong>4.</strong> Nintendo Switch Lite – Best for Portable Play
                    </p>
                </accordion-group>
                <accordion-group heading="What are shipping times and costs?">
                    <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                    <ul>
                        <li>a console</li>
                        <li>Two Joy-Con controllers that are detachable</li>
                        <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                        <li>Two straps for turning the Joy-Cons into individual controllers</li>
                        <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                    </ul>
                </accordion-group>
                <accordion-group heading="What payment methods can I use?">
                    <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                </accordion-group>
                <accordion-group heading="Can I use my own domain name?">
                    <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                    <ul>
                        <li>a console</li>
                        <li>Two Joy-Con controllers that are detachable</li>
                        <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                        <li>Two straps for turning the Joy-Cons into individual controllers</li>
                        <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                    </ul>
                </accordion-group>
                <accordion-group heading="What kind of customer service do you offer?">
                    <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                </accordion-group>
                <accordion-group heading="My account says it is locked. How can I unlock it?">
                    <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                    <ul>
                        <li>a console</li>
                        <li>Two Joy-Con controllers that are detachable</li>
                        <li>A grip that enables you to combine them into a single gamepad for play on the TV</li>
                        <li>Two straps for turning the Joy-Cons into individual controllers</li>
                        <li>A dock which you can use to connect your console to the television for traditional gameplay</li>
                    </ul>
                </accordion-group>
                <accordion-group heading="How often do I have to change my password?">
                    <p>Anim pariatur cliche reprehenderit, enim eiusmod high life accusamus terry richardson ad squid. 3 wolf moon officia aute, non cupidatat skateboard dolor brunch. Food truck quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor, sunt aliqua put a bird on it squid single-origin coffee nulla assumenda shoreditch et. Nihil anim keffiyeh helvetica, craft beer labore wes anderson cred nesciunt sapiente ea proident. Ad vegan excepteur butcher vice lomo.</p>
                </accordion-group>
            </accordion>
        </div>
    </div>
</div>

<div class="subscribe-area bg-f9f9f9 ptb-100">
    <div class="container">
        <app-subscribe></app-subscribe>
    </div>
    <div class="shape4"><img src="assets/img/shape4.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape12.png" alt="image"></div>
    <div class="shape14"><img src="assets/img/shape13.png" alt="image"></div>
    <div class="shape15"><img src="assets/img/shape14.png" alt="image"></div>
</div>