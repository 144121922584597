<div class="courses-area instructor-courses pb-100">
    <div class="container">
        <div class="section-title instructor-title-color">
            <span style="font-size: 20px;" class="sub-title">Idea Behind GYAAN</span>
            <div class="mainIdeadv">
                <div>
                    <img class="prtmainImg" src="assets/img/frontpageportrait.jpg" >
                </div>
                    <div>
                        <p style="text-align: justify;font-size: 17px;margin:20px">
                            <b>GYAAN</b> has been conceived with the aim of bridging the gap between the average individual and a comprehensive understanding of the financial market. This knowledge will serve as a catalyst for individuals to cultivate personal wealth. As we envision transforming India from a nation of savers into a realm of investors, <b>GYAAN</b> will emerge as a pivotal player in our mission of <b>"HAR GHAR FINANCE.</b>"
                        </p>
                    </div>
 
            </div>

           
        </div>
        <div class="row justify-content-center">
            <h2 style="text-align: center" class="el-messiri-font">Top Selling Courses</h2>
            <div *ngFor="let c of courseList" class="col-lg-4 col-md-6">
                <div class="single-courses-box">
                    <div class="courses-image">
                        <a [routerLink]="'/course/'+c._id" class="d-block image">
                            <img [src]="c.mainPhoto" class="thmbimgg" alt="image">
                        </a>
                       
                        <div class="price shadow">₹ {{c.actualPrice}}</div>
                    </div>
                    <div class="courses-content">
                        <div class="course-author d-flex align-items-center">
                            <img [src]="c?.instructorDetails?.trainerImg" style="object-fit: contain;" class="rounded-circle" alt="image">
                            <span>{{c?.instructorDetails?.name}}</span>
                        </div>
                        <h3 class="el-messiri-font"><a [routerLink]="'/course/'+c._id">{{c.name}}</a></h3>
                        <p style="text-align: justify;">{{c.description}}</p>
                        <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                            <li><i class='flaticon-agenda'></i> {{c.noOfModules}} Modules</li>
                            <!-- <li><i class='flaticon-people'></i> {{c.noOfEnrolledStudents}} Students</li> -->
                        </ul>
                    </div>
                </div>
            </div>
 
        </div>
    </div>
</div>