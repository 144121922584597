<div class="funfacts-area-three bg-f9f9f9">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-3 col-sm-3 col-6">
                <div class="funfacts-box">
                    <div class="content">
                        <h3 class="inter-font"><span class="odometer" [countUp]="3279">00</span></h3>
                        <p>Enrolled Learners</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-6">
                <div class="funfacts-box">
                    <div class="content">
                        <h3 class="inter-font"><span class="odometer" [countUp]="250">00</span></h3>
                        <p>Online Instructors</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-6">
                <div class="funfacts-box">
                    <div class="content">
                        <h3 class="inter-font"><span class="odometer" [countUp]="1926">00</span></h3>
                        <p>Finished Sessions</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-sm-3 col-6">
                <div class="funfacts-box">
                    <div class="content">
                        <h3 class="inter-font"><span class="odometer" [countUp]="100">00</span>%</h3>
                        <p>Satisfaction Rate</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>