<div class="motivation-about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="motivation-about-image">
                    <lightgallery [settings]="settings" [onBeforeSlide]="onBeforeSlide">
                        <a
                            data-lg-size="1280-720"
                            data-src="https://www.youtube.com/watch?v=68mTG-fxzX4"
                            data-sub-html="<p>Watch Video</p>"
                            class="d-block"
                        >
                            <img src="assets/img/motivation-home/motivation-about.jpg" alt="image">
                            <div class="video-btn-box">
                                <div class="video-btn popup-youtube">
                                    <i class="flaticon-play"></i> Watch Video
                                </div>
                            </div>
                        </a>
                    </lightgallery>
                    <div class="motivation-shape-4">
                        <img src="assets/img/motivation-home/motivation-shape-4.png" alt="image">
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="motivation-about-me-content">
                    <span class="sub-title">ABOUT ME</span>
                    <h2 class="jost-font">Keep you accountable for your goals as a business and as a leader within your organization</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    <div class="quote">
                        “I wake up every morning and think to myself, ‘how far can I push this company in the next 24 hours.’”
                    </div>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                    <a routerLink="/profile-authentication" class="default-btn"><i class="flaticon-user"></i>Join For Free<span></span></a>
                </div>
            </div>
        </div>
    </div>
    <div class="motivation-shape-5">
        <img src="assets/img/motivation-home/motivation-shape-5.png" alt="image">
    </div>
</div>