import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-online-training-school',
    templateUrl: './online-training-school.component.html',
    styleUrls: ['./online-training-school.component.scss']
})
export class OnlineTrainingSchoolComponent implements OnInit {

    constructor() { }

    ngOnInit(): void {
    }

}