import { Component, OnInit } from '@angular/core';
import { ApiHttpService } from 'src/app/core/services/api-http.service';
import { LoaderService } from 'src/app/core/services/loader.service';

@Component({
  selector: 'app-courses-style-four',
  templateUrl: './courses-style-four.component.html',
  styleUrls: ['./courses-style-four.component.scss']
})
export class CoursesStyleFourComponent implements OnInit {
  courseData:any;
  constructor(public apiHttp:ApiHttpService,public loader:LoaderService) { }

  ngOnInit(): void {
    this.loader.showLoader();
    this.apiHttp.get('course/allcourses').subscribe(data=>{
      this.loader.hideLoader();
      this.courseData = data['result'];
      console.log(this.courseData);
    })
  }

}
